/* eslint-disable import/no-anonymous-default-export */
import {
    SET_EXPORT_OPTIONS,
    SET_EXECUTE_EXPORT,
    SET_DELIVER_EXPORT,
    SET_EXPORT_CONTENT,
} from '../actions/_types'

const initialState = {
    selectAll: false,
    productInfo: true,
    pepNotes: false,
    pdtMembers: false,
    sitRep: false,         
    scopeWithNotes: true,
    resCommitments: false,
    miscOutsourced: false,
    budgetReview: false,
    productSchedule: true,
    approvalStatus: true,
    commentsResponses: false,
    expenseReport: false,
    // 
    isCommitmentsDollars: false,
    isCommitmentsHours: true,
    isBudgetDollars: false,
    isBudgetHours: true,
    isScheduleGrid: true,
    isScheduleGantt: false,
    inMiscellaneous: false,
    inOutsourced: false,
   // inOsDetail: false,
    //
    // inExpense: false, // See expenseReport
    inExpWBS: true,
    inOverhead: true,
    inExpSections: false,
    inExpResources: false,
    isExpDollars: false,
    isExpHours: true,
    //
    executeExport: false,
    deliverExport: false,
    //
    exportContent: {
        generalInfo: {
            productID: null, // 0-9
            productTitle: '', // ''
            p2Numbers: [], // []
            projectManager: '', // From PDT  Info
            technicalLead: '', // From PDT Info
            productType: '',
            programType: '',
            fundingType: '', // ?
            createDate: null, //
            startDate: null, // ?
            scopeRevision: null, // ?
            productNotes: '',
        },
        pepNotes: {
            background: '',
            productDescription: '',
            assumptions: '',
            changeManagement: '',
            safetyPlan: '',
            riskManagement: '',
            communications: '',
            valueManagement: '',
            dataManagement: '',
            qualityManagement: '',
            closeOut: '',
            versionRemarks: '',
        },
        pdtMembers: [],
        sitRep: [],
        //WBS with Notes
        scopeNotes: {
            header: [],
            rows: []
        },
        // 
        commitments: { // Not sure how to manage this yet.
            header: [],
            rowsD: [],
            rowsH: [],
        },
        //
        miscellaneous: { // All Dollars
            header: [],
            rows: []
        },
        outsourced: { // All Dollars
            header: [],
            rows: [],
        },
        //
        budget: {
            header: [],
            rowsD: [],
            rowsH: [],
        },
        //
        schedule:{
            header: [],
            rows: []
        },
        //
        approvals: {
            header: [],
            rows: []
        },
        //
        expense: {
            header: [],
            rowsD: [],
            rowsH: []
        }
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_EXPORT_OPTIONS:
            return { 
                ...state,
                ...state.export,
                [action.field]: action.payload
            };
        case SET_EXECUTE_EXPORT:
            return {
                ...state,
                executeExport: action.payload,
            }
        case SET_DELIVER_EXPORT:
            return {
                ...state,
                deliverExport: action.payload,
            }
        case SET_EXPORT_CONTENT:
            return {
                ...state,
                exportContent: {
                    ...state.exportContent,
                    [action.field]: action.payload
                }
            }
        default:
            return {
                ...state
            }
        }
};