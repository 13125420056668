// Added to make sure the values exist, compensating for the lack of assignment in resource commitments, seems to work so.

import {
    isEmpty,
    findIndex,
    toUpper,
    concat,
    each,
    isNil,
    toNumber,
    merge,
    orderBy,
    split,
} from 'lodash'

const wbsCheckForSort = (inCols = [], inRows = []) => {
    let tmpCols = []
    let tmpRows = []
    let tmpSplitWBS = []

    if(!isEmpty(inCols)) {
        if(findIndex(inCols, (obj) => { return toUpper(obj.field) === 'WBS'}) > -1) {
            if(findIndex(inCols, (obj) => { return obj.field === 'deliverableNumber'}) === -1) {
                tmpCols = concat(inCols, [
                    {
                        headerName: 'Deliverable Number',
                        field: 'deliverableNumber',
                        width: 50,
                        hide: true,
                        
                    },
                    {
                        headerName: 'Task Number',
                        field: 'taskNumber',
                        width: 50,
                        hide: true,
                    },
                    {
                        headerName: 'SubTask Number',
                        field: 'subtaskNumber',
                        width: 50,
                        hide: true,
                    },                                        
                ])
            } else {
                tmpCols = inCols
            }
            if(!isEmpty(inRows) && (findIndex(tmpCols, (obj) => { return obj.field === 'deliverableNumber'}) !== -1)) {
                each(inRows, (value) => {
                    tmpSplitWBS = split(!isEmpty(value.WBS) ? value.WBS : value.wbs, '.', 3) // TODO: Ugly for transition of columns, ugh.
                    if(isNil(tmpSplitWBS[0])){ tmpSplitWBS[0] = 0}
                    if(isNil(tmpSplitWBS[1])){ tmpSplitWBS[1] = 0}
                    if(isNil(tmpSplitWBS[2])){ tmpSplitWBS[2] = 0}
                    tmpRows.push(merge(value, {
                        deliverableNumber: toNumber(tmpSplitWBS[0]), 
                        taskNumber: toNumber(tmpSplitWBS[1]), 
                        subtaskNumber: toNumber(tmpSplitWBS[2]),
                    }))
                })
            } else {
                tmpRows = inRows
            }
        }
    }
    return {outCols: tmpCols, outRows: orderBy(tmpRows,['deliverableNumber', 'taskNumber', 'subtaskNumber'], ['asc', 'asc', 'asc'])}
}

export default wbsCheckForSort