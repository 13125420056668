/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
import { getConfiguredCache }  from 'money-clip';
import ms from 'milliseconds'
import pkg from '../../package.json'
import ver from '../resources/meta.json'
// import { promiseToNav } from '../utility'

import axpi from './query'

import { 
    has,
    isNil, 
} from 'lodash';

global.pkgVersion = pkg.version

if(!has(global, 'emsVersion')) {
    global.emsVersion = ver
}

export const getVersion = async () => {
    let outVersion = {
        version: null,
        verNum: null,
        maxAge: ms.months(1)
    }
    try {
        let rq = await axpi.get(`${process.env.REACT_APP_ROOT}resources/meta.json`)
            outVersion = {...outVersion, version: rq.data.version, verNum: rq.data.verNum}
            global.emsVersion = outVersion
            return outVersion
    }
    catch(err){
        outVersion = {...outVersion, version: ver.version, verNum: ver.verNum}
        global.emsVersion = outVersion
        console.error('Unable to Fetch meta version, using package version:', err)
        return outVersion
    }
}

export const cache = getConfiguredCache({
    version: ver.version,
    maxAge: ms.months(1)
})

export const checkVersion = async () => {
    const readVersion = await getVersion() 
    if(has(readVersion, 'version')) {
        const chk = cache.get('version', {version: readVersion.version, maxAge: readVersion.maxAge}).then((version) => {
            return version
        })
        return chk
    } 
}

export const checkExpire = () => {
    const chk = checkVersion()
    chk.then((version) => {
        if(isNil(version)) {
            const op = cache.clear()
            op.then(() => {
                window.location.reload(true)
            })
        }
        if((has(global.emsVersion, 'version') && has(version, 'version'))) {
            if((version.version !== global.emsVersion.version) || (version.verNum !== global.emsVersion.verNum)) {
                const op = cache.clear()
                op.then(() => {
                    window.location.reload(true)
                })
            }
        } else if (!has(version, 'version')) { 
            const op = cache.clear()
            op.then(() => {
                if(has(global.emsVersion, 'version')) {
                    const sop = cache.set('version', {version: global.emsVersion.version, verNum: global.emsVersion.verNum, maxAge: ms.months(1)})
                    sop.then(() => {window.location.reload(true)})
                } else if (has(ver, 'version')) {
                    const sop = cache.set('version', {version: ver.version, verNum: ver.verNum, maxAge: ms.months(1)})
                    sop.then(() => {window.location.reload(true)})
                }
            })
        }
    })
    chk.catch((err) => {
        console.error('<! Error fetching valid cache version !>', err) // Shouldn't really happen
    })
}

checkExpire()

export default cache
