
const filterTFNaw = (TFN = null) => {
    switch (TFN) {
        case 'T': {
            return true
        }
        case 'F': {
            return false
        }
        case 'true': {
            return 'T'
        }
        case 'false': {
            return 'F'
        }
        case true: {
            return 'T'
        }
        case false: {
            return 'F'
        }
        default:
            return TFN
    }
}

export default filterTFNaw