import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import queryReducer from './reducer_query';
import agReducer from './reducer_ag_grid';
import locReducer from './reducer_location';
import userReducer from './reducer_user';
import messageReducer from './reducer_messages';
import productReducer from './reducer_product';
import reportalReducer from './reducer_reportal'
import controlsReducer from './reducer_controls'
import lcTrackingReducer from './reducer_lc_tracking'
import rptResourceReducer from './reducer_rpt_resource'
import mgmtResourceTracking from './reducer_mgmt_resource_tracking'
import mgmtApprovals from './reducer_mgmt_approvals'
import mgmtVersionControl from './reducer_mgmt_version_control'
import authReducer from './reducer_auth'
import exportReducer from './reducer_export'
import emailReducer from './reducer_email'
import cacheConsent from './reducer_cache_consent'
import cacheEntity from './reducer_cache_entity'
import cacheVersion from './reducer_cache_version'
import clientIdle from './reducer_idle'
import envReducer from './reducer_environment'
import sysStat from './reducer_sys'
import hasAccess from './reducer_access'
import sysConfig from './reducer_config'
import genericValidation from './reducer_validation'
import emsConfiguration from './reducer_config_module'
import userPrefs from './reducer_user_prefs'
import tmpControls from './reducer_superficial_controls'
import mediaIndex from './reducer_media'
import miscTaxes from './reducer_tax'

const rootReducer = combineReducers({
    environment: envReducer,
    sysConfig: sysConfig,
    sysStat: sysStat,
    idleStatus: clientIdle,
    version: cacheVersion,
    consent: cacheConsent,
    org: cacheEntity,
    auth: authReducer,
    user: userReducer,
    userPrefs: userPrefs,
    export: exportReducer,
    email: emailReducer,
    message: messageReducer,
    product: productReducer,
    query: queryReducer,
    grid: agReducer,
    location: locReducer,
    form: formReducer,
    reportal: reportalReducer,
    controls: controlsReducer,
    lctracking: lcTrackingReducer,
    rptResource: rptResourceReducer,
    mgmtResourceTracking: mgmtResourceTracking,
    mgmtApprovals: mgmtApprovals,
    mgmtVersionControl: mgmtVersionControl,
    access: hasAccess,
    media: mediaIndex,
    validation: genericValidation,
    configuration: emsConfiguration,
    tmpControls,
    miscTaxes,
});

export default rootReducer;
