/* eslint-disable import/no-anonymous-default-export */
import {
    SET_AUTH_LOGIN,
    SET_AUTH_LOGOUT,
    SET_AUTH_STATUS,
    SET_REVALIDATE,
    SET_AUTH_MANUAL_LOGOUT,
    SET_AUTH_MANUAL_LOGIN,
    SET_AUTH_PRE_AUTH,
    SET_AUTH_QUERY_IS_PENDING,
    SET_AUTH_REATTEMPTS,
    SET_AUTH_VALIDATE_ACTION,
} from '../actions/_types'

const initialState = {
    token: {
        raw: null,
        parts: [],
        userPart: null 
    },
    data: {},
    isValid: false,
    hasError: false,
    error: null,
    lastError: {},
    reValidate: false,
    vAction: null,
    manualLogout: false,
    manualLogin: false,
    preAuth: true,
    isPending: false,
    reAttempts: 1,
    status: {
        initialTS: 0,
        expiresIn: 0,
        refreshExpiresIn: 0
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTH_LOGIN:
            return { 
                ...state, 
                token: {
                    raw: action.token.raw, 
                    parts: action.token.parts, 
                    userPart: action.token.userPart 
                }, 
                data: action.data, 
                isValid: action.isValid, 
                hasError: action.hasError, 
                error: action.error,
                lastError: action.lastError,
                reAttempts: action.reAttempts,
                status: action.status,
            };
        case SET_AUTH_LOGOUT:
            return { 
                ...state, 
                //...initialState 
                token: {
                    raw: null,
                    parts: [],
                    userPart: null 
                },
                data: {},
                isValid: false,
                hasError: false,
                error: null,
                lastError: {},
                reAttempts: 1,
                reValidate: false,
                vAction: null,
                status: {
                    initialTS: 0,
                    expiresIn: 0,
                    refreshExpiresIn: 0
                }
            };
        case SET_AUTH_STATUS:
            return { 
                ...state,
                isValid: action.isValid,
                hasError: action.hasError, 
                error: action.error,
                lastError: action.lastError,
            };
        case SET_REVALIDATE:
            return { 
                ...state,
                reValidate: action.payload
            };
        case SET_AUTH_MANUAL_LOGOUT:
            return { ...state, manualLogout: action.payload};
        case SET_AUTH_MANUAL_LOGIN:
            return { ...state, manualLogin: action.payload};          
        case SET_AUTH_PRE_AUTH:
            return { ...state, preAuth: action.payload};
        case SET_AUTH_QUERY_IS_PENDING:
            return { ...state, isPending: action.payload};
        case SET_AUTH_REATTEMPTS:
            return {...state, reAttempts: action.payload}
        case SET_AUTH_VALIDATE_ACTION:
            return {...state, vAction: action.payload}                                                                                      
        default:
            return state
    }
};
