/* eslint-disable import/no-anonymous-default-export */
import {
    SET_GENERIC_VALIDATION_ERROR,
} from '../actions/_types'

const initialState = {
    hasError: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_GENERIC_VALIDATION_ERROR:
            return { ...state, hasError: action.payload }                                                        
        default:
            return state
    }
};