import { 
    isEmpty, 
    isNil,
    replace,
} from 'lodash'

export const replaceEncode = (inStr = '') => {
    if(isNil(inStr) || isEmpty(inStr)) return inStr
    return encodeURIComponent(replace(inStr, RegExp('[/]', 'g'), '\\')) 
}

export const replaceForeSlashWithTilde = (inStr = '') => {
    if(isNil(inStr) || isEmpty(inStr)) return inStr
    return replace(inStr, RegExp('[/]', 'g'), '~')
}

export const replaceAsterisk = (inStr = '') => {
    if(isNil(inStr) || isEmpty(inStr)) return inStr
    return replace(inStr, RegExp('[*]', 'g'), '')
}

export const replaceSlashP2WBS = (inStr = '') => {
    if(isNil(inStr) || isEmpty(inStr)) return inStr
    return replace(inStr, RegExp('[/]', 'g'), '#~')
}