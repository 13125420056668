import moment from 'moment'
import ms from 'milliseconds'

import {
    isEmpty,
    isNil,
    each,
    has, 
} from 'lodash'

// const fourhours = ms.hours(4)
// const thirtyminutes = ms.minutes(30)
// const twelvehours = ms.hours(12)
// const sevendays = ms.days(7)
const tStr = 'YYYY-MM-DDTHH:mm:ss.SSSZ'

export const estimateSameDay = (strDatetime = '0000-00-00T00:00:00.000Z') => {
   
    const formatOffset = moment(localizeUTCDateTime(strDatetime)).format('YYYY-MM-DD')
    const localNowDate = moment().format('YYYY-MM-DD')
    
    return (localNowDate === formatOffset) ? true : false
}

export const localizeUTCDateTime = (strDatetime = '0000-00-00T00:00:00.000Z') => {

    const yr = moment(strDatetime, tStr).format('YYYY')
    const mon = moment(strDatetime, tStr).format('MM')
    const day = moment(strDatetime, tStr).format('DD')
    const hr = moment(strDatetime, tStr).format('HH')
    const min = moment(strDatetime, tStr).format('mm')
    const sec = moment(strDatetime, tStr).format('ss')
    const mz = moment(strDatetime, tStr).format('SSS')

    const d = Date.UTC(yr, mon -1, day, hr, min, sec, mz)

    let offset = ms.minutes(moment().utcOffset())
    
    if(offset < 0) {
        offset = offset * -1
    } else if (offset > 0) {
        offset = offset * 1
    }
    return d + offset
}

export const processAnticipated = (inData = {}) => {
    let outRows = []
    if((isEmpty(inData) || isNil(inData)) || inData.length < 1) return outRows
    if(has(inData[0], 'anticipatedRestorationDate') && has(inData[0], 'lineItem') && has(inData[0], 'lastRefreshDate')) {
        //
        each(inData, (value) => {
        //     const outDate = localizeUTCDateTime(value.lastRefreshDate)
        //     const fourhrs = outDate + fourhours
        //     const thirtymin = outDate + thirtyminutes
        //     const twelvehrs = outDate + twelvehours
        //     const sevendys = outDate + sevendays  
       
        //     switch(trim(value.lineItem)) {
        //         case 'REPORTING: TRACKING REPORT & SITREP REPORTS (FULL REFRESH)': {
        //             value.anticipatedRestorationDate = moment(fourhrs).utc().format(tStr)
        //             break;
        //         }
        //         case 'REPORTING: BUDGET TRACKING CHART DASHBAORD REFRESH': {
        //             value.anticipatedRestorationDate =  moment(thirtymin).utc().format(tStr)
        //             break;
        //         }
        //         case 'REPORTING: RESOURCE, WORKLOAD, & EXPENSE REPORTS (FULL REFRESH)': {
        //             value.anticipatedRestorationDate = moment(fourhrs).utc().format(tStr)
        //             break;
        //         }
        //         case 'REPORTING: RESOURCE, WORKLOAD, & EXPENSE REPORTS (FAST REFRESH)': {
        //             value.anticipatedRestorationDate = moment(thirtymin).utc().format(tStr)
        //             break;
        //         }
        //         case 'EMS: OVERHEAD FEE CALCULATIONS (FULL REFRESH)': {
        //             value.anticipatedRestorationDate = moment(twelvehrs).utc().format(tStr)
        //             break;
        //         }
        //         case 'EMS: OVERHEAD FEE CALCULATIONS (FAST REFRESH)': {
        //             value.anticipatedRestorationDate = moment(thirtymin).utc().format(tStr)
        //             break;
        //         }
        //         case 'EDW: LABOR CODE CHARGES DATA  (IN-DISTRICT & CROSS-CHARGE)': {
        //             value.anticipatedRestorationDate = moment(sevendys).utc().format(tStr)
        //             break;
        //         }
        //         case 'EDW: LABOR CODE LOOK UP DATA (IN-DISTRICT & CROSS-CHARGE)': {
        //             value.anticipatedRestorationDate = moment(sevendys).utc().format(tStr)
        //             break;
        //         }
        //         case 'EDW: EMPLOYEE DATA': {
        //             value.anticipatedRestorationDate = moment(sevendys).utc().format(tStr)
        //             break;
        //         }
        //         default: {
        //             // if(isNil(value.anticipatedRestorationDate) && !isNil(value.lastRefreshDate)) {
        //             //     value.anticipatedRestorationDate = 'UNKNOWN'
        //             // }
        //         }
        //    }            
           outRows.push(value)
        })
        return outRows
    }
}


export const convertMiliseconds = (miliseconds, format) => {
    var days, hours, minutes, seconds, total_hours, total_minutes, total_seconds;
    
    if((miliseconds === 0) || isNil(miliseconds)) return 0;

    total_seconds = parseInt(Math.floor(miliseconds / 1000));
    total_minutes = parseInt(Math.floor(total_seconds / 60));
    total_hours = parseInt(Math.floor(total_minutes / 60));
    days = parseInt(Math.floor(total_hours / 24));
  
    seconds = parseInt(total_seconds % 60);
    minutes = parseInt(total_minutes % 60);
    hours = parseInt(total_hours % 24);
    
    switch(format) {
      case 's':
          return total_seconds;
      case 'm':
          return total_minutes;
      case 'h':
          return total_hours;
      case 'd':
          return days;
      default:
          return { d: days, h: hours, m: minutes, s: seconds };
    }
  };