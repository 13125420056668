/* More slated for common configuration later, interceptors etc */
import axios from 'axios'

export const axpi = axios;

export function setToken(token) {
    axios.defaults.headers.common['Authorization'] =
        `Bearer ${token}`;
}

export default axpi
