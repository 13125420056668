/* eslint-disable import/no-anonymous-default-export */
import { 
    SET_REPORT_FCST,
    SET_REPORT_SELECTED,
    SET_REPORT_SECTION_CODE,
    SET_REPORT_SECTION_CODE_Z,
    SET_REPORT_RESOURCE,
    SET_REPORT_RESOURCE_Z,
    SET_REPORT_FISCAL_YEAR,
    SET_REPORT_FISCAL_YEAR_Z,
    SET_REPORT_METRIC,
    SET_REPORT_METRIC_Z,
    SET_REPORT_FILTER_ITEM,
    REPORT_FILTER_DISABLE,
    SET_REPORT_FILTER_FCST,
    SET_REPORT_FILTER_SECTION_CODE,
    SET_REPORT_FILTER_RESOURCE,
    SET_REPORT_FILTER_FY,
    SET_REPORT_FILTER_METRIC,
    SET_REPORT_PRODUCT_LIST,
    SET_REPORT_SELECTED_PRODUCT,
    SET_REPORT_PRODUCT_UPDATE, 
    SET_REPORT_FILTER_PRODUCT,
    SET_REPORT_RESOURCE_OPTIONS,
    SET_REPORTING_STATUS,
    SET_REPORT_FILTER_REPORTING_STATUS,
    FLUSH_REPORT_INFO,
    SET_REPORT_DATA_IS_LOADING,
} from '../actions/_types'

const initialState = {
    rptProduct: {
        productID: 0,
        productTitle: '',
        productList: [],
        updateSelect: false
    },
    fcst: {},
    reporting: {},
    selectedReport: {},
    sectionCode: {},
    sectionCodes: [],
    resource: {},
    resources: [],
    fiscalYear: [],
    fiscalYears: [],
    metric: {},
    metricOptions: [],
    rptFilter: {
        reporting: '',
        product: '',
        fcst: '',
        sectionCode: '',
        resource: [],
        fy: [],
        metric: '',
        run: false,
        refresh: false,
    },
    filterDisable: {
        fcst: false,
        reporting: false,
        sectionCode: false,
        resource: false,
        fy: false,
        metric: false,
    },
    rptResourceOptions: {
        showProducts: false,
        showSections: false,
        showResources: false,
        showActuals: true,
        showTotals: true,
    },
    isLoading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_REPORT_FCST:
            return { ...state, fcst: action.payload };
        case SET_REPORTING_STATUS:
            return { ...state, reporting: action.payload};
        case SET_REPORT_SELECTED:
            return { ...state, selectedReport: action.payload };
        case SET_REPORT_SECTION_CODE:
            return { ...state, sectionCode: action.payload };
        case SET_REPORT_SECTION_CODE_Z:
            return { ...state, sectionCodes: action.payload};
        case SET_REPORT_RESOURCE:
            return { ...state, resource: action.payload };
        case SET_REPORT_RESOURCE_Z:
            return { ...state, resources: action.payload };
        case SET_REPORT_FISCAL_YEAR:
            return { ...state, fiscalYear: action.payload };
        case SET_REPORT_FISCAL_YEAR_Z:
            return { ...state, fiscalYears: action.payload };
        case SET_REPORT_METRIC:
            return { ...state, metric: action.payload };
        case SET_REPORT_METRIC_Z:
            return { ...state, metricOptions: action.payload };
        case SET_REPORT_FILTER_ITEM:
            return { ...state, rptFilter: action.payload};
        case SET_REPORT_FILTER_FCST:
            return { ...state, rptFilter: {...state.rptFilter, fcst: action.payload }};
        case SET_REPORT_FILTER_REPORTING_STATUS: 
            return {...state, rptFilter: {...state.rptFilter, reporting: action.payload}};
        case SET_REPORT_FILTER_SECTION_CODE:
            return { ...state, rptFilter: {...state.rptFilter, sectionCode: action.payload}};
        case SET_REPORT_FILTER_RESOURCE:
            return { ...state, rptFilter: {...state.rptFilter, resource: action.payload}};
        case SET_REPORT_FILTER_FY:
            return { ...state, rptFilter: {...state.rptFilter, fy: action.payload}};
        case SET_REPORT_FILTER_METRIC:
            return { ...state, rptFilter: {...state.rptFilter, metric: action.payload}};
        case SET_REPORT_FILTER_PRODUCT:
            return { ...state, rptFilter: {...state.rptFilter, product: action.payload}};            
        case REPORT_FILTER_DISABLE:
            return { ...state, filterDisable: action.payload };             
        case SET_REPORT_PRODUCT_LIST:
            return {...state, rptProduct: {...state.rptProduct, productList: action.payload}};
        case SET_REPORT_PRODUCT_UPDATE:
            return {...state, rptProduct: {...state.rptProduct, updateSelect: action.payload}};
        case SET_REPORT_SELECTED_PRODUCT:
            return {
                ...state, 
                rptProduct: {
                    ...state.rptProduct, 
                    productID: action.payload.ID, 
                    productTitle: action.payload.Title, 
                    updateSelect: action.update
                }, 
                rptFilter: {...state.rptFilter, product: action.payload.Title}
            };
        case SET_REPORT_RESOURCE_OPTIONS:
            return { ...state, rptResourceOptions: action.payload };
        case FLUSH_REPORT_INFO:
            return { ...state, ...initialState};
        case SET_REPORT_DATA_IS_LOADING:            
            return { ...state, isLoading: action.payload};
        default:
            return state
    }
};
