/* eslint-disable import/no-anonymous-default-export */
import {
    SET_MISC_TAXES,
    SET_MISC_TAXES_CURRENT_ROW,
    SET_MISC_TAXES_CURRENT_CELL,
    SET_MISC_TAXES_OVER_RIDE_KEYS,
    SET_MISC_TAXES_CAN_REMOVE,
} from '../actions/_types'

const initialState = {
    taxesMisc: [],
    orKeys: [],
    currentRow: {},
    currentCell: null,
    volatile: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_MISC_TAXES:
            return {
                ...state,
                taxesMisc: action.payload
            }
        case SET_MISC_TAXES_OVER_RIDE_KEYS:
            return {
                ...state,
                orKeys: action.payload
            }
        case SET_MISC_TAXES_CURRENT_ROW:
            return {
                ...state,
                currentRow: action.payload
            }
        case SET_MISC_TAXES_CURRENT_CELL:
            return {
                ...state,
                currentCell: action.payload
            }            
        case SET_MISC_TAXES_CAN_REMOVE:
            return {
                ...state,
                volatile: action.payload
            }                                             
        default:
            return state
    }
};