    
import {
    split,
    capitalize,
    each,
    toLower,
    indexOf,
    isEmpty, 
} from 'lodash'

const normalizeEmail = (inStr = '') => {
    let outStr = inStr

    if(!isEmpty(inStr)) {
        let tmpArr = split(inStr, '@', 2)
        let tmpName = split(tmpArr[0], '.')
    
        let cnt = tmpName.length
        each(tmpName, (value) => {
            let tmpV = capitalize(value)

            if(indexOf(value, '-') > 0) {
                let tmpH = split(value, '-')
                let lenH = tmpH.length
                let tmpOut = ''

                each(tmpH, (tValue, tKey) => {
                    if(lenH === tmpH.length) {
                        tmpOut = capitalize(tValue) + '-'
                    }
                    if(lenH > 1 && lenH !== tmpH.length) {
                        tmpOut += capitalize(tValue) + '-'
                    }
                    if(lenH === 1) {
                        tmpOut += capitalize(tValue)
                        tmpV = tmpOut
                    }
                    lenH--
                })
            }
            if(cnt === tmpName.length && cnt !== 1) {
                outStr = tmpV + '.'
            }
            if(cnt > 1 && cnt !== tmpName.length) {
                outStr += tmpV + '.'
            }
            if(cnt === 1) {
                outStr += tmpV + '@' + toLower(tmpArr[1])
            }
            cnt--
        })
    }
    return outStr
}

export default normalizeEmail