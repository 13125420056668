import {
    map,
    uniq,
    trim, 
} from 'lodash'

const readInSections = (inOrgs = []) => {
    const sections = map(uniq(map(inOrgs, 'sectionCode')), trim)
    return sections
}

export default readInSections