/* eslint-disable import/no-anonymous-default-export */
import { 
    SET_IS_DEV,
    SET_CURRENT_SYSTEM_STATUS,
    SET_EMULATION_ACTIVE,
    SET_EMULATION_PRODUCT,
    SET_EMULATION_USER,
    SET_EMULATION_ENTITY,
    SET_EMULATION_SOURCE,
    SET_EMULATION
} from '../actions/_types'

const initialState = {
    isDev: (process.env.NODE_ENV === 'development') ? true : false,
    systemStatus: [],
    statusTag: null,
    emulation: { // used to emulate a user product combination overriding roles distributed to logged in account. Dev Only
        active: false,
        productID: -1, 
        userID: -1, 
        entity: '', 
        source: ''
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_IS_DEV:
            return { 
                ...state, 
                action 
            };
        case SET_CURRENT_SYSTEM_STATUS:
            return { 
                ...state, 
                systemStatus: action.payload,
                statusTag: action.tag 
            };
        case SET_EMULATION_ACTIVE:
            return {
                ...state,
                emulation: {...state.emulation, active: action.payload}
            };
        case SET_EMULATION_PRODUCT:
            return {
                ...state,
                emulation: {...state.emulation, productID: action.payload}
            };
        case SET_EMULATION_USER:
            return {
                ...state,
                emulation: {...state.emulation, userID: action.payload}
            }
        case SET_EMULATION_ENTITY:
            return {
                ...state,
                emulation: {...state.emulation, entity: action.payload}
            }
        case SET_EMULATION_SOURCE:
            return {
                ...state,
                emulation: {...state.emulation, source: action.payload}
            }
        case SET_EMULATION:
            return {
                ...state,
                emulation: action.payload
            }

        default:
            return state
    }
};