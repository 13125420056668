/* eslint-disable import/no-anonymous-default-export */
import { 
    SET_MGMT_RESOURCE_TRACKING_GRAPH_SELECTED,
    SET_MGMT_RESOURCE_TRACKING_FILTER_SELECTED,
    STORE_MGMT_RESOURCE_TRACKING_GRAPH_DATA_DOLLARS,
    STORE_MGMT_RESOURCE_TRACKING_GRAPH_DATA_HOURS,
    SET_MGMT_RESOURCE_TRACKING_GRAPH_LOADED,
    FLUSH_MGMT_RESOURCE_TRACKING,
} from '../actions/_types'

const initialState = {
    filterSelected: null,
    graphSelected: null,
    graphData: {
        byDollars: {
            resourceLineItems: [],                
        },
        byHours: {
            resourceLineItems: [],
        },
    },
    loaded: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_MGMT_RESOURCE_TRACKING_GRAPH_SELECTED:
            return {...state, graphSelected: action.payload}
        case SET_MGMT_RESOURCE_TRACKING_FILTER_SELECTED:
            return {...state, filterSelected: action.payload}            
        case STORE_MGMT_RESOURCE_TRACKING_GRAPH_DATA_DOLLARS: 
            return {...state, graphData: {...state.graphData, byDollars: action.payload} }
        case STORE_MGMT_RESOURCE_TRACKING_GRAPH_DATA_HOURS:
            return {...state, graphData: {...state.graphData, byHours: action.payload}}
        case SET_MGMT_RESOURCE_TRACKING_GRAPH_LOADED:
            return {...state, loaded: action.payload}
        case FLUSH_MGMT_RESOURCE_TRACKING:
            return {...state, ...initialState}
        default:
            return state
    }
};
