/* eslint-disable import/no-anonymous-default-export */
import {
    SET_BROADCAST_MESSAGE,
    SET_MESSAGE,
    CLEAR_MESSAGE,
    CLEAR_MESSAGE_Z,
    SET_MESSAGE_TO_QUEUE,
} from '../actions/_types'

const initialState = {
    lastType: null,
    broadcast: {type: '', info: ''},
    hasMessage:  false,
    message: {origin: '', type: '', info: '', n: false, timestamp: ''},
    hasError: false,
    error: {origin: '', type: '', info: '', n: false, timestamp: ''},
    demo: false,
    messageQueue: [{origin: '', type: '', info: '', n: false, timestamp: ''}]
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_BROADCAST_MESSAGE: {
            return { 
                ...state, 
                broadcast: action.payload 
            };
        }
        case SET_MESSAGE: {
            if(action.payload.type === 'error') {
                return { 
                    ...state, 
                    error: action.payload,
                    lastType: action.payload.type, 
                    hasError: true,
                    hasMessage: false,
                    messageQueue: [...state.messageQueue, action.payload]
                }
            } else {
                return { 
                    ...state,
                    hasError: false,
                    lastType: action.payload.type, 
                    message: action.payload, 
                    hasMessage: true,
                    messageQueue: [...state.messageQueue, action.payload]
                };
            }
        }
        case CLEAR_MESSAGE: {
            return { 
                ...state,
                ...initialState.lastType, 
                ...initialState.error, 
                ...initialState.hasError,
                ...initialState.message,
                ...initialState.hasMessage,
            };
        }
        case CLEAR_MESSAGE_Z: {
            return { 
                ...state,
                lastType: initialState.lastType,  
                messageQueue: initialState.messageQueue 
            };
        }
        case SET_MESSAGE_TO_QUEUE: {
             return { 
                ...state, 
                messageQueue: [...state.messageQueue, action.payload]
            };
        }                
        default: {
            return state
        }
    }
}
