import {
    each,
} from 'lodash'

export const chromaItems = (inKey = 0) => {
    let outColor = ''
    const fixedList = [
        "#008000",
        "#0000ff",
        "#ff0000",
        "#800080",
        "#ffa500",
        "#a0522d",
        "#778899",
        "#006400",
        "#00008b",
        "#8b0000",
        "#483d8b",
        "#ff6347",
        "#543005",
        "#505050",
        "#4dac26",
        "#4292c6",
        "#d01c8b",
        "#9400d3",
        "#fd8d3c",
        "#8c510a",
        "#737373",
        "#2e8b57",
        "#40e0d0",
        "#6a5acd",
        "#da70d6",
        "#f08080",
        "#b8860b",
        "#00441b",
        "#08306b",
        "#67000d",
        "#3f007d",
        "#7f2704",
        "#543005",
        "#556b2f",
        "#008080",
        "#ef3b2c",
        "#6a51a3",
        "#f16913",
        "#bf812d",
        "#006d2c",
        "#08519c",
        "#a50f15",
        "#54278f",
        "#a63603",
        "#b15928",
        "#4daf4a",
        "#377eb8",
        "#e41a1c",
        "#984ea3",
        "#ff7f00",
    ]

    if(inKey >= 0) {
        each(fixedList, (value, key) => {
            if(key === inKey) {
                outColor = value
            }
        }) 
    } else return
    return outColor
}
