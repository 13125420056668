/* eslint-disable import/no-anonymous-default-export */
import {
    SET_EMAIL_OPTION,
    SET_EMAIL_USE_CLIENT, 
    SET_EMAIL_TO,
    SET_EMAIL_ALT_TO,
    SET_EMAIL_CC,
    SET_EMAIL_ALT_CC,
    SET_EMAIL_SUBJECT,
    SET_EMAIL_BODY,
    SET_EMAIL_ALT_BODY,
    SET_EMAIL_ATTACHMENT,
    SET_EMAIL_WL_MANAGERS,
} from '../actions/_types'

const initialState = {
    useClient: false,
    catType: '',
    to: [],
    altTo: [],
    cc: [],
    altCC: [],
    subject: '',
    body: [],
    altBody: [],
    attachment: {},
    emailString: '',
    wlManagers: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_EMAIL_USE_CLIENT:
            return { ...state, useClient: action.payload }
        case SET_EMAIL_OPTION:
            return { 
                ...state,
                ...state.email,
                [action.field]: (!action.payload) ? initialState[action.field] : action.payload // This may not work 
            };
        case SET_EMAIL_TO:
            return { ...state, to: action.payload }                          
        case SET_EMAIL_ALT_TO:
            return { ...state, altTo: action.payload }
        case SET_EMAIL_CC:
            return { ...state, cc: action.payload }
        case SET_EMAIL_ALT_CC:
            return { ...state, altCC: action.payload }
        case SET_EMAIL_SUBJECT:
            return { ...state, subject: action.payload }
        case SET_EMAIL_BODY:
            return { ...state, body: action.payload }
        case SET_EMAIL_ALT_BODY:
            return { ...state, altBody: action.payload }
        case SET_EMAIL_ATTACHMENT:
            return { ...state, attachment: action.payload }
        case SET_EMAIL_WL_MANAGERS:
            return { ...state, wlManagers: action.payload}                                               
        default:
            return {
                ...state
            }
    }
};