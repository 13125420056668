/* eslint-disable import/no-anonymous-default-export */
import { 
    SET_CACHE_SYS_VERSION,
} from '../actions/_types'

const initialState = {
    version: null,
    verNum: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CACHE_SYS_VERSION:
            return { 
                ...state,
                version: action.version,
                verNum: action.verNum,
            }                                                      
        default:
            return state
    }
};