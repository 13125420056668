/* eslint-disable import/no-anonymous-default-export */
import {
    SET_CACHE_USER_CONSENT,
} from '../actions/_types'

const initialState = {
    consent: false,
   // tag: 0
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CACHE_USER_CONSENT:
            return { ...state, consent: action.consent, /* tag: action.tag*/}                                                        
        default:
            return state
    }
};