/* eslint-disable import/no-anonymous-default-export */
import {
    SET_ACCESS_BULK,
    SET_DENIED_ACCESS,
    SET_ROLES_ENFORCED,
    SET_MODULE_ACCESS,
    SET_ROLES_GLOBAL_LOCK,
} from '../actions/_types'

import { 
    roleConfig,
} from '../utility'

let init = roleConfig()

const initialState = {
    enforced: init.enforceRoles,
    globalLock: !init.globalAccess,
    baseLoc: null,
    adminAccess: false,
    userAccess: false,
    deniedAccess: false,
    hasError: false,
    hash: null,
    location: null,
    main: null,
    module: null,
    modules: [],
    moduleAccess: false,
    msgError: null,
    proc: null,
    roles: [],
    segments: [],
    sub: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ACCESS_BULK:
            return {...state, ...action.payload}
        case SET_DENIED_ACCESS:
            return {...state, deniedAccess: action.payload}
        case SET_ROLES_ENFORCED:
            return {...state, enforced: action.payload} 
        case SET_MODULE_ACCESS:
            return {...state, moduleAccess: action.payload} 
        case SET_ROLES_GLOBAL_LOCK:
            return {...state, globalLock: action.payload}
        default:
            return state
    }
};