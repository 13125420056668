/* eslint-disable import/no-anonymous-default-export */
import {
    SET_EMS_CONFIG,
    // SET_EMS_CONFIG_BY_KEY
}  from '../actions/_types'

import { 
    roleConfig,
} from '../utility'

let init = roleConfig()

const initialState = {
    globalAccess: init.globalAccess,
    enforceRoles: init.enforceRoles,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_EMS_CONFIG:
            return { ...state, ...action.payload }                          
        // case SET_EMS_CONFIG_BY_KEY:
        //     return { ...state, [action.payload.key]: action.payload.value }                              
        default:
            return state
    }
};