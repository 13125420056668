/* eslint-disable import/no-anonymous-default-export */
import { 
    PUSH_LOCATION,
    SET_CURRENT_HREF, 
    SET_CURRENT_LOC_HASH,
    SET_CURRENT_LOC_SEARCH,
    SET_CURRENT_LOC_ALL,
} from '../actions/_types'

const initialState = {
    href: null,
    path: null,
    search: null,
    hash: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case PUSH_LOCATION:
            return {...state, path: action.payload}
        case SET_CURRENT_HREF:
            return {...state, href: action.payload}
        case SET_CURRENT_LOC_HASH:
            return {...state, hash: action.payload}
        case SET_CURRENT_LOC_SEARCH :
            return {...state, search: action.payload}
        case SET_CURRENT_LOC_ALL:
            return {
                ...state,
                path: action.payload.pathname,
                href: action.payload.href,
                hash: action.payload.hash,
                search: action.payload.search,
            }
        default:
            return state
    }
}