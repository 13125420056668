/* eslint-disable no-unused-vars */
import axpi from '../lib/query'

import { 
    accessByLocation,
 } from './'

import configInfo from '../resources/ems-config.json';

import {
    filterTFNaw,
} from './'

import {
    each,
    indexOf,
    has,
    find,
    isEmpty,
    isNil,
    toUpper 
} from 'lodash'

const isDev = (process.env.NODE_ENV === 'development') ? true : false

// Needs maintenance if harvested roles change, among many others
const definedRoles = [
    'ssb',
    'management',
    'reportal',
    'productTitle',
    'productInfo',
    'p2',
    'pep',
    'pdt',
    'wbs',
    'commitments',
    'scheduling',
    'mb',
    'ow',
    'status',
    'pracTracking',
    'versionControl',
    'approvals',
    'ems',
    'configurations',
    'roles',
    'admin',
    'productCreation',
    'sitrep',
    'timeStamp'
]

let globalAccess = null
let enforceRoles = null

export const getConfig = () => {
    const src = `${process.env.REACT_APP_ROOT}resources/ems-config.json`
    const rq = axpi.get(src)
    rq.then((response) => {
        if(response.status === 200)
        globalAccess = response.data.globalAccess
        enforceRoles = response.data.enforceRoles
    })
    rq.catch((err) => {
        console.error('Unable to Fetch configuration file:', err)
    });
}

getConfig()

if(isNil(globalAccess)) globalAccess = configInfo.globalAccess
if(isNil(enforceRoles)) enforceRoles = configInfo.enforceRoles

export const roleConfig = () => {
    return {
        enforceRoles,
        globalAccess
    }
}

export const setLocalConfig = (inConfig = {enforceRoles, globalAccess}) => {
    enforceRoles = inConfig.enforceRoles
    globalAccess = inConfig.globalAccess

    return {
        enforceRoles,
        globalAccess
    }
}

export const defaultRoles = (src = 'local') => {
    return [
        {role: 'global', hasAccess: globalAccess}, // added later for heirarchical apex
        {role: 'ems', hasAccess: true, parent: 'global'}, // false will disable access
        {role: 'ssb', hasAccess: true, parent: 'ems'}, 
        {role: 'management', hasAccess: false, parent: 'ems'},
        {role: 'reportal', hasAccess: false, parent: 'ems'},
        {role: 'productTitle', hasAccess: true, parent: 'configurations'}, // false
        {role: 'productInfo', hasAccess: false, parent: 'ssb'},
        {role: 'p2', hasAccess: true, parent: 'ssb'},
        {role: 'pep', hasAccess: false, parent: 'ssb'},
        {role: 'pdt', hasAccess: false, parent: 'ssb'},
        {role: 'wbs', hasAccess: false, parent: 'ssb'},
        {role: 'commitments', hasAccess: false, parent: 'ssb'},
        {role: 'scheduling', hasAccess: false, parent: 'ssb'},
        {role: 'mb', hasAccess: false, parent: 'ssb'},
        {role: 'ow', hasAccess: false, parent: 'ssb'},
        {role: 'status', hasAccess: false, parent: 'management'},
        {role: 'pracTracking', hasAccess: false, parent: 'management'},
        {role: 'versionControl', hasAccess: false, parent: 'management'},
        {role: 'approvals', hasAccess: false, parent: 'management'},
        {role: 'configurations', hasAccess: true, parent: 'ems'}, // Protected
        {role: 'roles', hasAccess: false, parent: 'configurations'},
        {role: 'admin', hasAccess: true, parent: 'global'}, // Protected
        {role: 'productCreation', hasAccess: true, parent: 'configurations'},
        {role: 'sitrep', hasAccess: false, parent: 'ssb'},
        {source: src, timeStamp: Date.now()}
    ]
}

export const userRole = (roles = [], locked = true, isDev = false, enforced = enforceRoles) => {
    
    return new Promise((resolve, reject) => {
        let outRoles = []

        if(isEmpty(roles)) return reject(outRoles = {hasError: true, msgError: 'Error, No Input Roles', proc: 'userRole'})
        // isDev Test Roles, uer impersonation in _SysAccess
        // if(isDev) {
        //     outRoles = accessByLocation(window.location, defaultRoles('testing'), enforced)
        // } else {
        //     outRoles = accessByLocation(window.location, roles, enforced)
        // }
        // Normal
        outRoles = accessByLocation(window.location, roles, enforced)
            
        if(has(outRoles, 'modules')) { //
            const isAdmin = find(outRoles.modules, (obj) => {return obj.role === 'admin'})
            const isUser = find(outRoles.modules, (obj) => {return obj.role === 'ems'})

            if(!isNil(isAdmin) && isAdmin.hasAccess) { // Admin true, all true administrative roles
                const enforce = ['ems', 'configurations', 'global', 'roles', 'productTitle', 'productCreation']
                each(outRoles.modules, (value) => {
                    if(enforced) {
                        if(indexOf(enforce, value.role) > -1) {
                            value.hasAccess = true
                        }
                    }
                    if(!enforced) { // Disrespect all roles
                        value.hasAccess = true
                    }
                })
                outRoles = {
                    ...outRoles,
                    adminAccess: true,
                    userAccess: true,
                    deniedAccess: false
                }
                if(isDev) {
                    console.log('ADMIN', enforced, outRoles)
                }
                return resolve(outRoles)
            }
            
            if(!isNil(isUser) && isUser.hasAccess) { // EMS true, User
                const protect = ['admin', 'ems', 'configurations', 'global', 'roles', 'productTitle', 'productCreation']
                if(!enforced) { // disresepct the rules if not administrative
                    each(outRoles.modules, (value) => {
                        if(indexOf(protect, value.role) < 0) {
                            value.hasAccess = true
                        }
                    })
                }
                outRoles = {
                    ...outRoles,
                    adminAccess: false,
                    userAccess: true,
                    deniedAccess: false
                }
                if(isDev) {
                    console.log('USER', enforced, outRoles)
                }
               return resolve(outRoles)
            }
            
            if(!isNil(isUser) && !isUser.hasAccess) { // EMS false. 
                // Access to everything not administrative
                const protect = ['admin', 'ems', 'configurations', 'global', 'roles', 'productTitle', 'productCreation']   
                if(!enforced) {
                    each(outRoles.modules, (value) => {
                        if(indexOf(protect, value.role) < 0) {
                            value.hasAccess = true
                        }
                    })
                    outRoles = {
                        ...outRoles,
                        adminAccess: false,
                        userAccess: true, // 
                        deniedAccess: false // Change these if ever you want a lock out while not enforced
                    }
                }
                if(enforced) { // Crush their will
                    each(outRoles.modules, (value) => {
                        value.hasAccess = false
                    })
                    outRoles = {
                        ...outRoles,
                        adminAccess: false,
                        userAccess: false,
                        deniedAccess: true
                    }
                }

                if(isDev) {
                    console.log('USER FALSE', enforced, outRoles)
                }
                return resolve(outRoles)
            }          
            
            if(isNil(isUser)) {  // undefined, this should never happen with checks up above
                if(enforced) {
                    if(isDev) {
                        console.log('DENIED By Lack of Evidence', outRoles)
                    }
                    outRoles = {
                        ...outRoles,
                        adminAccess: false,
                        userAccess: false,
                        deniedAccess: true
                    }
                   return resolve(outRoles)
                }
                
                if (!enforced) { // Being unnecessarily explicit 
                    if(isDev) {
                        console.log('USER By Lack of Evidence', outRoles)
                    }
                    outRoles = {
                        ...outRoles,
                        adminAccess: false,
                        userAccess: true,
                        deniedAccess: false
                    }
                    return resolve(outRoles)
                }
            }
        }
        // resolve(outRoles)                
    }) // Promise
}

// Call with roles props and role name, delivers requested role.
export const moduleAccess = (roles = [], roleName = null) => {
    return new Promise((resolve, reject) => {
        let outRole = {}

        if(isNil(roleName)) {
            return reject({hasAccess: false, error: 'No Module Name Input', proc: 'moduleAccess'})

        } else if(isEmpty(roles) || roles.length < 1) { 
            return resolve({hasAccess: false, error: 'No Roles Input', proc: 'moduleAccess' })
        } else {
            if(has(roles[0], 'role')) {
                outRole = find(roles, (obj) => {return toUpper(obj.role) === toUpper(roleName)})
                if(isNil(outRole)) {
                    return resolve({hasAccess: false, error: `Role: ${roleName} not found.`, proc: 'moduleAccess'})

                } else {
                    if(isDev) {console.log('MODULE ACCESS - OUTROLE', outRole)}
                    return resolve(outRole)
                }
            }
        }
    })
}

// Provide the props.access object will determine access by modules, rollName = child
export const childAccess = (access = {}, roleName = null) => {
    return new Promise((resolve, reject) => {
        if(has(access, 'modules')) {
            if(isEmpty(access.modules)) {
               reject({hasAccess: false, error: 'Object Modules Empty', proc: 'childAccess'})
            } else if(!isNil(roleName)) {
                const tmpRoot = moduleAccess(access.modules, roleHeirarchy(roleName).role) // Root call verify
                tmpRoot.then((rsRoot) => {
                    if(has(rsRoot, 'parent')) {
                        if(has(rsRoot, 'hasAccess') && !rsRoot.hasAccess) {
                            if(isDev) {console.info('NO ACCESS, Roles, Child Access', rsRoot)}
                            resolve(rsRoot) // No Access
                        } else {
                            const tmpChild = moduleAccess(access.modules, rsRoot.parent)
                            tmpChild.then((rsChild) => {
                                if(has(rsChild, 'hasAccess') && !rsChild.hasAccess) {
                                    if(isDev) {console.info('NO ACCESS, Roles, rsChild (Parent)', rsChild)}
                                    resolve(rsChild.hasAccess) // No Access based on parent result                                
                                } else if (!isNil(rsChild) && has(rsChild, 'parent')) {
                                    const tmpParent = childAccess(access, rsChild.parent)
                                    tmpParent.then((rsParent) => {
                                        resolve(rsParent)
                                    })
                                } else {
                                    if(isDev) {console.info('NO PARENT, End of Chain', rsChild)}
                                    resolve(rsChild)
                                }
                            })
                            tmpChild.catch((err) => {
                                console.error('tmpChild In Roles child Access', err)
                                reject({hasAccess: false, error: 'Object Modules Empty', proc: 'childAccess'}, err)
                            })
                        }
                    } else {
                        if(isDev) {
                            console.error('DONE, NO PARENT, End of Chain', rsRoot)
                        }
                        resolve(rsRoot)
                        // Something, return verdict
                    }
                })
                tmpRoot.catch((err) =>{
                    console.error('tmpRoot in childAccess', err)
                    reject({hasAccess: false, error: 'No Role or Modules', proc: 'childAccess'}, err)
                })
            }
        } else {
            reject({hasAccess: false, error: 'Access Object Incomplete'})
        }
    })
}

export const parseRoles = (inObj = {}, inSource = undefined) => {
    return new Promise((resolve, reject) => {
        let outRoles = [{role: 'global', hasAccess: globalAccess}]
        each(inObj, (value, key) => {
            if(indexOf(definedRoles, key) > -1) {
                outRoles.push({
                    role: key,
                    hasAccess: filterTFNaw(value),
                    parent: roleHeirarchy(key).parent
                })
            } 
        })
        if(isDev) {
            // console.log('OUT ROLES', outRoles)
        }
        outRoles.push({source: inSource, timeStamp: Date.now()})
        return resolve(outRoles)
    })
}

export const roleHeirarchy = (inKey = '') => {
    switch(inKey) { // Return key to make useful for more than one thing
        case 'admin': 
        case 'ems': { 
            return {role: inKey, parent: 'global'}
        }
        case 'ssb': 
        case 'management':
        case 'reportal': 
        case 'configurations': { 
            return {role: inKey, parent: 'ems'}
        }
        case 'sitrep':                
        case 'productInfo':
        case 'p2':
        case 'pep':
        case 'pdt': 
        case 'wbs':
        case 'commitments': 
        case 'scheduling': 
        case 'mb': 
        case 'ow': { 
            return {role: inKey, parent: 'ssb'}
        }
        case 'status': 
        case 'pracTracking':
        case 'versionControl': 
        case 'approvals': { 
            return {role: inKey, parent: 'management'}
        }
        case 'roles': 
        case 'productTitle': 
        case 'productCreation': { 
            return {role: inKey, parent: 'configurations'}
        }
        default: {
            return {role: inKey, parent: 'unknown'}
        }
    }
}

