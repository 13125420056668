import React from 'react'
import cache, {checkExpire} from '../lib/cache';

const contentLoading = () => {
    return (
        <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

const LoadingComponent = (props) => {
    if(props.error) {
        const op = cache.clear()
        op.then(() => {
            checkExpire()
        })
        op.then(()=> {
            return props.retry
        })
        // return (<div><br />Error loading resource. <button className="btn btn-default btn-md" onClick={props.retry}>Retry</button></div>)
    } else if (props.timedOut) {
        return (<div>Timed out: <button className="btn btn-default btn-md" onClick={props.retry}>Retry</button></div>)
    } else if (props.pastDelay) {
        if (props.isLoading) {
            return(contentLoading())
        }
    } else {
        return null;
    }
}
export default LoadingComponent
