/* eslint-disable import/no-anonymous-default-export */
import { 
    STORE_SELECTED_TRACKING_P2,
    STORE_TRACKING_P2_Z,
    STORE_SELECTED_TRACKING_FWI,
    STORE_TRACKING_FWI_Z,
    STORE_SELECTED_TRACKING_LC,
    STORE_TRACKING_LC_Z,
    STORE_TRACKING_SELECTION_GRID,
    STORE_TRACKING_PRAC_RESULT_GRID,
    SET_PRAC_SELECTION_UPDATE,
    STORE_SELECTED_TRACKING_RC,
    STORE_TRACKING_RC_Z,
    CLEAR_TRACKING_STATE,
    SET_TRACKING_FILTER_OPTIONS,
    SET_TRACKING_GRAPH_SELECTED,
    STORE_TRACKING_GRAPH_DATA_DOLLARS,
    STORE_TRACKING_GRAPH_DATA_HOURS,
    SET_TRACKING_GRAPH_LOADED,
    CLEAR_TRACKING_MOST,
    SET_PRAC_FLUSH_LOCAL,
    SET_LC_TRACKING_SEARCH_ACTION,
    SET_LC_P2_SEARCH_ACTION,
    SET_LC_WI_SEARCH_ACTION,
    SET_LC_LC_SEARCH_ACTION,
    RESET_LC_SEARCH_ACTIONS,
    SET_UPDATE_TRACKED,
} from '../actions/_types'

const initialState = {
    selectedP2: {},
    p2z: [],
    selectedFWI: {},
    workItems: [],
    selectedLC: {},
    selectedRC: {},
    resourceCodes: [],
    laborCodes: [],
    gridSelection: [],
    gridPRAC: [],
    pracSelectUpdate: false,
    updateTracked: false,
    graphTrackingFilterOptions: {
        showSections: false,
        showResources:  false,
    },
    graphSelected: {},
    graphData: {
        byDollars: {
            productLineItems: [],
            sectionLineItems: [],
            resourceLineItems: [],                
        },
        byHours: {
            productLineItems: [],
            sectionLineItems: [],
            resourceLineItems: [],
        },
    },
    loaded: false,
    flushLocal: false,
    searchAction: false,
    searchP2Action: false,
    searchWIAction: false,
    searchLCAction: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case STORE_SELECTED_TRACKING_P2:
            return {
                ...state,
                selectedP2: action.payload 
            };
        case STORE_TRACKING_P2_Z:
            return {
                ...state,
                p2z: action.payload
            }
        case STORE_SELECTED_TRACKING_FWI:
            return {
                ...state,
                selectedFWI: action.payload 
            };
        case STORE_TRACKING_FWI_Z:
            return {
                ...state,
                workItems: action.payload
            }
        case STORE_SELECTED_TRACKING_LC:
            return {
                ...state,
                selectedLC: action.payload 
            };
        case STORE_TRACKING_LC_Z:
            return {
                ...state,
                laborCodes: action.payload
            }
            case STORE_SELECTED_TRACKING_RC:
            return {
                ...state,
                selectedRC: action.payload 
            };
        case STORE_TRACKING_RC_Z:
            return {
                ...state,
                resourceCodes: action.payload
            }                         
        case STORE_TRACKING_SELECTION_GRID:
            return {
                ...state,
                gridSelection: action.payload
            }
        case STORE_TRACKING_PRAC_RESULT_GRID:
            return {
                ...state,
                gridPRAC: action.payload
            }
        case SET_PRAC_SELECTION_UPDATE:
            return {
                ...state,
                pracSelectUpdate: action.payload
            }
        case SET_PRAC_FLUSH_LOCAL:
            return {
                ...state,
                flushLocal: action.payload
            }            
        case CLEAR_TRACKING_STATE:
            return {
                ...state,
                ...initialState
            }
        case CLEAR_TRACKING_MOST:
            return {
                ...state,
                selectedP2: action.payload.selectedP2, 
                p2z: action.payload.p2z, 
                selectedFWI: action.payload.selectedFWI, 
                workItems: action.payload.workItems, 
                selectedLC: action.payload.selectedLC, 
                selectedRC: action.payload.selectedRC, 
                resourceCodes: action.payload.resourceCodes, 
                laborCodes: action.payload.laborCodes, 
                gridSelection: action.payload.gridSelection, 
                gridPRAC: action.payload.gridPRAC, 
                pracSelectUpdate: action.payload.pracSelectUpdate,
                searchAction: initialState.searchAction,
                searchP2Action: initialState.searchP2Action,
                searchWIAction: initialState.searchWIAction,
                searchLCAction: initialState.searchLCAction,
            }
        case SET_TRACKING_FILTER_OPTIONS:
            return { ...state, graphTrackingFilterOptions: action.payload }
        case SET_TRACKING_GRAPH_SELECTED:
            return {...state, graphSelected: action.payload}
        case STORE_TRACKING_GRAPH_DATA_DOLLARS: 
            return {...state, graphData: {...state.graphData, byDollars: action.payload} }
        case STORE_TRACKING_GRAPH_DATA_HOURS:
            return {...state, graphData: {...state.graphData, byHours: action.payload}}
        case SET_TRACKING_GRAPH_LOADED:
            return {...state, loaded: action.payload}
        case SET_LC_TRACKING_SEARCH_ACTION:
            return {...state, searchAction: action.payload}
        case SET_LC_P2_SEARCH_ACTION:
            return {
                ...state, 
                searchP2Action: action.payload,
                searchWIAction: initialState.searchWIAction,
                searchLCAction: initialState.searchLCAction
            }
        case SET_LC_WI_SEARCH_ACTION:
            return {
                ...state, 
                searchWIAction: action.payload,
                searchP2Action: initialState.searchP2Action,
                searchLCAction: initialState.searchLCAction
            }
        case SET_LC_LC_SEARCH_ACTION:
            return {
                ...state, 
                searchLCAction: action.payload,
                searchP2Action: initialState.searchP2Action,
                searchWIAction: initialState.searchWIAction
            }
        case RESET_LC_SEARCH_ACTIONS:
            return {
                ...state, 
                searchAction: initialState.searchAction,
                searchP2Action: initialState.searchP2Action,
                searchWIAction: initialState.searchWIAction,
                searchLCAction: initialState.searchLCAction
            }
        case SET_UPDATE_TRACKED: {
            return {
                ...state,
                updateTracked: action.payload
            }
        }                                                
        default:
            return state
    }
};
