/* eslint-disable import/no-anonymous-default-export */
import { 
    SET_CACHE_USER_ENTITY,
} from '../actions/_types'

const initialState = {
    entity: null,
    tag: 0
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CACHE_USER_ENTITY:
            return { ...state, entity: action.entity, tag: action.tag}                                                      
        default:
            return state
    }
};