/* eslint-disable import/no-anonymous-default-export */
import { 
    SET_MGMT_APPROVALS_SELECTED,
    SET_MGMT_APPROVALS_COMMENT_SELECTED,
    SET_MGMT_APPROVALS_RESPONSE_SELECTED,
    SET_MGMT_APPROVALS_REPONSES_FOR_COMMENT,
    STORE_MGMT_APPROVALS_COMMENTS,
    STORE_MGMT_APPROVALS_RESPONSES,
    SET_MGMT_APPROVALS_WHOAMI,
    PURGE_MGMT_APPROVALS,
} from '../actions/_types'

const initialState = {
    approvalSelected: {},
    commentSelected: {},
    responseSelected: {},
    responsesForComment: [],
    comments: [],
    responses: [],
    whoami: {},
    loaded: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_MGMT_APPROVALS_SELECTED:
            return {...state, approvalSelected: action.payload}
        case SET_MGMT_APPROVALS_COMMENT_SELECTED:
            return {...state, commentSelected: action.payload}            
        case SET_MGMT_APPROVALS_RESPONSE_SELECTED: 
            return {...state, responseSelected: action.payload}
        case SET_MGMT_APPROVALS_REPONSES_FOR_COMMENT:
            return {...state, responsesForComment: action.payload}
        case STORE_MGMT_APPROVALS_COMMENTS:
            return {...state, comments: action.payload}
        case STORE_MGMT_APPROVALS_RESPONSES:
            return {...state, responses: action.payload}
        case SET_MGMT_APPROVALS_WHOAMI:
            return {...state, whoami: action.payload}
        case PURGE_MGMT_APPROVALS:
            return {...state, ...initialState}
        default:
            return state
    }
};
