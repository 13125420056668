// Obsolete, fields added at query.

import {
    isEmpty,
    findIndex,
    concat,
    toUpper,
    isNil,
    each,
    split,
    sortBy,
    merge,
    toNumber, 
} from 'lodash'

const wbsSplitForSort = (inCols = [], inRows = []) => {
    let tmpCols = []
    let tmpRows = []
    let tmpSplitWBS = []

    if(!isEmpty(inCols)) {
        if(findIndex(inCols, (obj) => { return toUpper(obj.field) === 'WBS'}) > -1) {
            if(findIndex(inCols, (obj) => { return obj.field === 'sortMaster'}) === -1) {
                tmpCols = concat(inCols, [
                    {
                        headerName: 'Sort Master',
                        field: 'sortMaster',
                        width: 50,
                        hide: true,
                        
                    },
                    {
                        headerName: 'Sort Task',
                        field: 'sortTask',
                        width: 50,
                        hide: true,
                    },
                    {
                        headerName: 'Sort SubTask',
                        field: 'sortSub',
                        width: 50,
                        hide: true,
                    },                                        
                ])
            } else {
                tmpCols = inCols
            }
            if(!isEmpty(inRows) && (findIndex(tmpCols, (obj) => { return obj.field === 'sortMaster'}) !== -1)) {
                each(inRows, (value) => {
                    tmpSplitWBS = split(!isEmpty(value.WBS) ? value.WBS : value.wbs, '.', 3) // TODO: Ugly for transation of columns, ugh.
                    if(isNil(tmpSplitWBS[0])){ tmpSplitWBS[0] = 0}
                    if(isNil(tmpSplitWBS[1])){ tmpSplitWBS[1] = 0}
                    if(isNil(tmpSplitWBS[2])){ tmpSplitWBS[2] = 0}
                    tmpRows.push(merge(value, {
                        sortMaster: toNumber(tmpSplitWBS[0]), 
                        sortTask: toNumber(tmpSplitWBS[1]), 
                        sortSub: toNumber(tmpSplitWBS[2]),
                    }))
                })
            } else {
                tmpRows = inRows
            }
        }
    }
    return {outCols: tmpCols, outRows: sortBy(tmpRows, ['sortMaster', 'sortTask', 'sortSub'], ['asc', 'asc', 'asc'])}
}

export default wbsSplitForSort
