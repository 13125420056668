/* eslint-disable import/no-anonymous-default-export */
import { 
    SET_RSC_REPORT_FILTER,
    CLEAR_RSC_REPORT_FILTER,
    PURGE_RSC_REPORT_DATA,
    SET_RSC_REPORT_GRID_PRODUCT_DOLLARS,
    SET_RSC_REPORT_GRID_RESOURCE_DOLLARS,
    SET_RSC_REPORT_GRID_PRODUCT_HOURS,
    SET_RSC_REPORT_GRID_RESOURCE_HOURS,
    SET_RSC_REPORT_GRAPH_RESOURCE_HOURS,
    SET_RSC_REPORT_GRAPH_RESOURCE_DOLLARS,
    SET_GRAPH_SELECTED,
    SET_GRAPH_UPDATE_KICKER,
    SET_RSC_REPORT_GRAPH_RAW_DATA_D,    
    SET_RSC_REPORT_GRAPH_RAW_DATA_H,
    SET_RSC_GRAPH_DASH_DATA
} from '../actions/_types'

const initialState = {
    filter: [],
    graphSelected: null,
    update: false,
    rptGrid: {
        byProductDollars: [],
        byProductHours: [],
        byResourceDollars: [],
        byResourceHours: []
    },
    rptGraph: {
        rawData: {d: [], h: []},
        byResourceDollars: {
            productLineItems: [],
            sectionLineItems: [],
            resourceLineItems: []
        },
        byResourceHours: {
            productLineItems: [],
            sectionLineItems: [],
            resourceLineItems: []
        },
        dashData: []
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_RSC_REPORT_FILTER:
            return { ...state, filter: action.payload };
        case CLEAR_RSC_REPORT_FILTER:
            return { ...state, filter: initialState.filter };
        case PURGE_RSC_REPORT_DATA:
            return { ...state, ...initialState };            
        case SET_RSC_REPORT_GRID_PRODUCT_DOLLARS:
            return { ...state, rptGrid: {...state.rptGrid, byProductDollars: action.payload}};
        case SET_RSC_REPORT_GRID_RESOURCE_DOLLARS:
            return { ...state, rptGrid: {...state.rptGrid, byResourceDollars: action.payload}};
        case SET_RSC_REPORT_GRID_PRODUCT_HOURS:
            return { ...state, rptGrid: {...state.rptGrid, byProductHours: action.payload}};
        case SET_RSC_REPORT_GRID_RESOURCE_HOURS:
            return { ...state, rptGrid: {...state.rptGrid, byResourceHours: action.payload}};
        case SET_RSC_REPORT_GRAPH_RESOURCE_HOURS:
            return { ...state, rptGraph: {...state.rptGraph, byResourceHours: action.payload}};
        case SET_RSC_REPORT_GRAPH_RESOURCE_DOLLARS:
            return { ...state, rptGraph: {...state.rptGraph, byResourceDollars: action.payload}}; 
        case SET_GRAPH_SELECTED: 
            return { ...state, graphSelected: action.payload} 
        case SET_GRAPH_UPDATE_KICKER:
            return {...state, update: action.payload}
        case SET_RSC_REPORT_GRAPH_RAW_DATA_D:
            return { ...state, rptGraph: {...state.rptGraph, rawData: {...state.rptGraph.rawData, d: action.payload}}}
        case SET_RSC_REPORT_GRAPH_RAW_DATA_H:
            return { ...state, rptGraph: {...state.rptGraph, rawData: {...state.rptGraph.rawData, h: action.payload}}}
        case SET_RSC_GRAPH_DASH_DATA:
            return {...state, rptGraph: {...state.rptGraph, dashData: action.payload}}                                  
        default:
            return state
    }
};
