
/* eslint-disable import/no-anonymous-default-export */
import { 
    SET_SCHEDULE_AUTO_REFRESH,
    SET_SHOW_P2_MILESTONES,
    STORE_P2_MILESTONES,
    STORE_INNER_WINDOW_SIZE,
} from '../actions/_types'

const initialState = {
    recalc: true,
    p2Milestones: false,
    p2ms: [],
    innerSize: {height: window.innerHeight, width: window.innerWidth}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SCHEDULE_AUTO_REFRESH:
            return { ...state, recalc: action.recalc }
        case SET_SHOW_P2_MILESTONES:
            return { ...state, p2Milestones: action.milestones }
        case STORE_P2_MILESTONES:
            return { ...state, p2ms: action.p2ms }
        case STORE_INNER_WINDOW_SIZE:
            return { ...state, innerSize: action.innerSize}
        default:
            return state
    }
};