import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { legacy_createStore as createStore } from 'redux'
import { applyMiddleware } from 'redux'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { history } from './lib'
import {
    cache,
} from './lib/cache';
import actionMap from './actions/persist-action-map'
// Middlewares
import ReduxPromise from 'redux-promise'
import ReduxThunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import ReduxPromiseMiddleware from 'redux-promise-middleware'
import getPersistMiddleware from 'redux-persist-middleware'
// Enhancers
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
// import { composeWithDevTools } from 'remote-redux-devtools';
//
import {LicenseManager} from 'ag-grid-enterprise';
import reportWebVitals from './reportWebVitals';

// Styles, Moved to component /src/containers/ems_css_load and /src/utility/css_preload

// Import Component and Container Modules
// import AppRoot from './app_root'

// import AsyncLoader from './components/asyncLoader'
import Loadable from 'react-loadable'
import LoadingComponent from './components/asyncLoadingDummy' // Needs work, lots

import reducers from './reducers'
//
// LicenseManager.setLicenseKey('United_States_Army_Corps_of_Engineers,_Portland_District__Engineering_Management_System_(EMS)_1Devs6_March_2020__MTU4MzQ1MjgwMDAwMA==132cde5f8b1a9235b7257efb943bbe77');
// License Update 'OK' with older librariy version (22.1.1), refer to https://www.ag-grid.com/javascript-data-grid/licensing/#set once libraires upgraded passed 22.1.1 for new loading scheme 
// LicenseManager.setLicenseKey('CompanyName=United States Army Corps of Engineers, Portland District,LicensedApplication=Engineering Management System (EMS),LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=0,AssetReference=AG-023216,ExpiryDate=31_December_2022_[v2]_MTY3MjQ0NDgwMDAwMA==2ec3a9b5b44a1c8f6d8cec1e7715f1be');
// LicenseManager.setLicenseKey('CompanyName=United States Army Corps of Engineers, Portland District,LicensedApplication=Engineering Management System (EMS),LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-034108,SupportServicesEnd=31_December_2023_[v2]_MTcwMzk4MDgwMDAwMA==44e6e8cdd5a179b903d6bac39eb2ce54');
LicenseManager.setLicenseKey('Using_this_AG_Grid_Enterprise_key_( AG-049841 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( United States Army Corps of Engineers, Portland District )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Engineering Management System (EMS) )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( Engineering Management System (EMS) )_need_to_be_licensed___( Engineering Management System (EMS) )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 30_December_2024 )____[v2]_MTczNTUxNjgwMDAwMA==e597f1c80a16518c1023d2e370b1c53e')

// const isDev = (process.env.NODE_ENV === 'development') ? true : false

const AsyncAppRoot = Loadable({
    loader: () => import(/* webpackChunkName: 'approot' */ './app_root'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,
})
const AsyncIntroLanding = Loadable({
    loader: () => import(/* webpackChunkName: 'introlanding' */ './containers/IntroLanding'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,
})
const AsyncRPTLanding = Loadable({
    loader: ()=> import(/* webpackChunkName: 'rptlanding' */ './containers/RPTLanding'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,
})
const AsyncEMSLanding = Loadable({
    loader: () =>  import(/* webpackChunkName: 'emslanding' */ './containers/EMSLanding'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,
})

const AsyncEMSConfig = Loadable({
    loader: () =>  import(/* webpackChunkName: 'emsconfig' */ './containers/EMSConfig'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,
})

const AsyncSSBScheduling = Loadable({
    loader: () => import(/* webpackChunkName: 'scheduling' */ './containers/SSBScheduling'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,    
})
const AsyncSSBMiscOutsourced = Loadable({
    loader: () => import(/* webpackChunkName: 'miscoutsourced' */ './containers/SSBMiscOutsourced'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,    
})
const AsyncSSBBudget = Loadable({
    loader: () => import(/* webpackChunkName: 'budgetreview' */ './containers/SSBBudget'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,    
})
const AsyncSSBCommitments = Loadable({
    loader: () => import(/* webpackChunkName: 'commitments' */ './containers/SSBCommitments'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,    
})
const AsyncSSBScope = Loadable({
    loader: () => import(/* webpackChunkName: 'scopedevelopment' */ './containers/SSBScope'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,    
})
const AsyncSSBGeneralInfo = Loadable({
    loader: () => import(/* webpackChunkName: 'generalinfo' */ './containers/SSBGeneralInfo'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,    
})

const AsyncProductManagement = Loadable({
    loader: () => import(/* webpackChunkName: 'mgmtlanding' */ './containers/MGMTLanding'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,    
})

const AsyncManagementStatusing = Loadable({
    loader: () => import(/* webpackChunkName: 'mgmtstatusing' */ './containers/MGMTStatusing'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,    
})

const AsyncManagementTracking = Loadable({
    loader: () => import(/* webpackChunkName: 'mgmttracking' */ './containers/MGMTBudgetTracking'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,    
})

const AsyncManagementApprovals = Loadable({
    loader: () => import(/* webpackChunkName: 'mgmttracking' */ './containers/MGMTApprovals'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,    
})

const AsyncManagementApprovalsSSB = Loadable({
    loader: () => import(/* webpackChunkName: 'mgmttrackingssb' */ './containers/MGMTApprovalsSSB'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,    
})

const AsyncManagementApprovalsResource = Loadable({
    loader: () => import(/* webpackChunkName: 'mgmttrackingres' */ './containers/MGMTApprovalsResource'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,    
})

const AsyncManagementVersionControl = Loadable({
    loader: () => import(/* webpackChunkName: 'mgmttracking' */ './containers/MGMTVersionControl'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,    
})

const AsyncManagementExpense = Loadable({
    loader: () => import(/* webpackChunkName: 'mgmtexpense' */ './containers/MGMTExpense'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,    
})

const AsyncNewsArchive = Loadable({
    loader: () => import(/* webpackChunkName: 'newsarchive' */ './containers/NewsArchive'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,    
})

const AsyncTrainingVideos = Loadable({
    loader: () => import(/* webpackChunkName: 'videoarchive' */ './containers/TrainingVideos'), 
    loading: LoadingComponent,
    delay: 500,
    timeout: 20000,    
})


// Enhancer - Redux DevTools
const composeEnhancers = composeWithDevTools({
    // name: 'EMS Development', 
    // realtime: true,
    // hostname: 'localhost',
    // port: 8000,
    serialize: {
        // Options
        features: {
            pause: true, // start/pause recording of dispatched actions
            lock: true, // lock/unlock dispatching actions and side effects    
            persist: true, // persist states on page reloading
            export: true, // export history of actions in a file
            import: 'custom', // import history of actions from a file
            jump: true, // jump back and forth (time travelling)
            skip: true, // skip (cancel) actions
            reorder: true, // drag and drop actions in the history list 
            dispatch: true, // dispatch custom actions or action creators
            test: true // generate tests for the selected actions        
        },
        options: { 
            // https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md
            // actionSanitizer, 
            // stateSanitizer,
        }
    }
});


const persistMiddleware = getPersistMiddleware({
    cacheFn: cache.set,
    logger: console.info,
    actionMap
})

// Begin Middleware Setup
const middleWares = []

middleWares.push(
    ReduxThunk, 
    ReduxPromise, 
    ReduxPromiseMiddleware(),
    persistMiddleware,
)

// Logger Options Setup
const loggerMiddleware = createLogger({
    predicate: (getState, action) => process.env.NODE_ENV === 'development' || process.env.REACT_APP_ROOT === '/test/'
    // predicate: (getState, action) => true
});
middleWares.push(loggerMiddleware)
// End Middleware Setup

// if(process.env.NODE_ENV === 'development') {
//     window.localStorage.setItem('debug', true);
// } else {
//     window.localStorage.removeItem('debug');
// }

cache.getAll().then((initialData) => {
const createStoreWithMiddleware = createStore(reducers, initialData, composeEnhancers(applyMiddleware(...middleWares)));

ReactDOM.render(
//   <React.StrictMode>
    <Provider store={createStoreWithMiddleware}>
        <Router basename={`${process.env.REACT_APP_ROOT}`} history={ history }>
        <div>
            <AsyncAppRoot />
            <Switch>
                <Route
                    exact
                    path={`${process.env.REACT_APP_ROOT}ssb/`}
                    component={AsyncSSBGeneralInfo} 
                />
                <Route
                    exact 
                    path={`${process.env.REACT_APP_ROOT}ssb/sv/`}
                    component={AsyncSSBScope} 
                />                
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}ssb/sv/scope`} 
                    component={AsyncSSBScope} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}ssb/sv/commitment`} 
                    component={AsyncSSBCommitments} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}ssb/sv/budget`}
                    component={AsyncSSBBudget} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}ssb/sv/misclabor`}
                    component={AsyncSSBMiscOutsourced}
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}ssb/sv/scheduling`}
                    component={AsyncSSBScheduling} 
                />
                <Route 
                    exact
                    path={`${process.env.REACT_APP_ROOT}mgmt/`}
                    component={AsyncProductManagement} 
                />
                <Route 
                    exact
                    path={`${process.env.REACT_APP_ROOT}mgmt/approvals/`}
                    component={AsyncManagementApprovals} 
                />
                <Route 
                    exact
                    path={`${process.env.REACT_APP_ROOT}mgmt/approvals/ssbreview`}
                    component={AsyncManagementApprovalsSSB} 
                />    
                <Route 
                    exact
                    path={`${process.env.REACT_APP_ROOT}mgmt/approvals/resreview`}
                    component={AsyncManagementApprovalsResource} 
                />                    
                <Route 
                    exact
                    path={`${process.env.REACT_APP_ROOT}mgmt/status/`}
                    component={AsyncManagementStatusing} 
                />
                <Route 
                    exact
                    path={`${process.env.REACT_APP_ROOT}mgmt/tracking/`} 
                    component={AsyncManagementTracking} 
                />
                <Route 
                    exact
                    path={`${process.env.REACT_APP_ROOT}mgmt/versions/`}
                    component={AsyncManagementVersionControl} 
                />
                <Route 
                    exact
                    path={`${process.env.REACT_APP_ROOT}mgmt/expense/`} 
                    component={AsyncManagementExpense} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}ems/`}
                    component={AsyncEMSLanding} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}ems/configuration/`}
                    component={AsyncEMSConfig} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}ems/configuration/product`}
                    component={AsyncEMSConfig} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}ems/configuration/organization`}
                    component={AsyncEMSConfig} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}ems/configuration/general`}
                    component={AsyncEMSConfig} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}ems/configuration/resources`}
                    component={AsyncEMSConfig} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}ems/configuration/taxes`}
                    component={AsyncEMSConfig} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}ems/configuration/roles`}
                    component={AsyncEMSConfig} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}ems/administration`}
                    component={AsyncEMSLanding} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}rpt/`}
                    component={AsyncRPTLanding} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}rpt/resource`}
                    component={AsyncRPTLanding} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}rpt/expense`}
                    component={AsyncRPTLanding} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}rpt/workload`} 
                    component={AsyncRPTLanding} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}rpt/sitrep`} 
                    component={AsyncRPTLanding} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}rpt/tracking`}
                    component={AsyncRPTLanding} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}rpt/proga`}
                    component={AsyncRPTLanding} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}rpt/lbalance`}
                    component={AsyncRPTLanding} 
                />
                <Route 
                    exact 
                    path={`${process.env.REACT_APP_ROOT}`} 
                    component={AsyncIntroLanding} 
                />
                <Route  
                    path={`${process.env.REACT_APP_ROOT}documentation/`}
                />
                <Route  
                    path={`${process.env.REACT_APP_ROOT}export/`}
                />
                <Route
                    exact  
                    path={`${process.env.REACT_APP_ROOT}archive/`}
                    component={AsyncNewsArchive}
                />
                <Route
                    exact  
                    path={`${process.env.REACT_APP_ROOT}training/`}
                    component={AsyncTrainingVideos}
                />
                <Route
                    exact  
                    path={`${process.env.REACT_APP_ROOT}resources/media/`}
                    component={AsyncTrainingVideos}
                />
                <Redirect to={`${process.env.REACT_APP_ROOT}`} />
            </Switch>
        </div>
        </Router>
    </Provider>,
  // </React.StrictMode>, 
  document.getElementById('root'))
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();