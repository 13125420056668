/* eslint-disable import/no-anonymous-default-export */
import {
    SET_RDS_LAST_STAT,
    SET_RDS_STATUS,
    SET_RDS_QUERY_PENDING,
    SET_RDS_ERR_STAT,
    SET_RDS_LAST_SUCCESS,
} from '../actions/_types'

const initialState = {
    lastSuccess: null,
    lastStat: null,
    localMS: null,
    rdsStatus: false,
    queryPending: false,
    error: null,
    errStatus: '',
    errMessage: '',
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_RDS_LAST_STAT:
            return {
                ...state,
                lastStat: action.lastStat,
                localMS: action.localMS
            }
        case SET_RDS_STATUS:
            return {
                ...state,
                rdsStatus: action.rdsStatus
            }
        case SET_RDS_QUERY_PENDING: 
            return {
                ...state,
                queryPending: action.queryPending
            }
        case SET_RDS_ERR_STAT:
            return {
                ...state,
                error: action.error,
                errStatus: action.status,
                errMessage: action.message
            }
        case SET_RDS_LAST_SUCCESS:
            return {
                ...state,
                lastSuccess: action.lastSuccess
            }                                           
        default:
            return state
    }
};