/* eslint-disable import/no-anonymous-default-export */
import {
    SET_USER_THEME,
    SET_USER_STYLE_PATHS,
    SET_USER_STYLE_LOADED,
    SET_AG_THEME,
} from '../actions/_types'

const initialState = {
    userTheme: '',
    stylePaths: [],
    styleLoaded: false,
    agTheme: ''
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_THEME:
            return {
                ...state,
                userTheme: action.payload
            }
        case SET_USER_STYLE_PATHS:
            return {
                ...state,
                stylePaths: action.payload
            }
        case SET_USER_STYLE_LOADED:
            return {
                ...state,
                styleLoaded: action.payload
            }
        case SET_AG_THEME:
            return {
                ...state,
                agTheme: action.payload
            }                                             
        default:
            return state
    }
};