/* eslint-disable import/no-anonymous-default-export */
import {
    SET_VC_DOLLARS, 
    SET_VC_PRODUCTS_DOLLARS,
    SET_VC_SECTIONS_DOLLARS,
    SET_VC_RESOURCES_DOLLARS,
    SET_VC_HOURS,
    SET_VC_PRODUCTS_HOURS,
    SET_VC_SECTIONS_HOURS,
    SET_VC_RESOURCES_HOURS,
    SET_VC_RESOURCE_FILTER,
    SET_VC_SECTION_FILTER,
    PURGE_VC,
    SET_VC_SELECTED_ROWS,
    SET_VC_GRAPH_SELECTED,
    SET_VC_FILTER_SELECTED,
    SET_VC_VIEW_TYPE,
    SET_VC_FILTER_ITEMS,
} from '../actions/_types'

const initialState = {
    graphData: {
        dollars: {
            productLineItems: [],
            sectionLineItems: [],
            resourceLineItems: []
        },
        hours: {
            productLineItems: [],
            sectionLineItems: [],
            resourceLineItems: []
        }
    },
    sectionFilter: [],
    resourceFilter: [],
    selectedRows: [],
    graphSelected: {label: 'Target Resource', value: 'Target Resource'},
    filterSelected: {label: 'Section Filter', value: 'Section Filter'},
    graphViewSelected: {label: 'Cumulative', value: 'Cumulative'},
    filterItems: {
        section: {},
        resource: {},
    },
    
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_VC_DOLLARS:
            return {...state, graphData: {...state.graphData, dollars: action.payload}}
        case SET_VC_PRODUCTS_DOLLARS:
            return {...state, graphData: {...state.graphData.dollars, productLineItems: action.payload }}
        case SET_VC_SECTIONS_DOLLARS:
            return {...state, graphData: {...state.graphData.dollars, sectionLineItems: action.payload }}
        case SET_VC_RESOURCES_DOLLARS:
            return {...state, graphData: {...state.graphData.dollars, resourceLineItems: action.payload }}
        case SET_VC_HOURS:
            return {...state, graphData: {...state.graphData, hours: action.payload}}            
        case SET_VC_PRODUCTS_HOURS:
            return {...state, graphData: {...state.graphData.hours, productLineItems: action.payload }}
        case SET_VC_SECTIONS_HOURS:
            return {...state, graphData: {...state.graphData.hours, sectionLineItems: action.payload }}
        case SET_VC_RESOURCES_HOURS:
            return {...state, graphData: {...state.graphData.hours, resourceLineItems: action.payload }} 
        case SET_VC_SECTION_FILTER:
            return {...state, sectionFilter: action.payload}
        case SET_VC_RESOURCE_FILTER:
            return {...state, resourceFilter: action.payload}
        case PURGE_VC:
            return {...state, ...initialState} 
        case SET_VC_SELECTED_ROWS:
            return {...state, selectedRows: action.payload}
        case SET_VC_GRAPH_SELECTED: 
            return {...state, graphSelected: action.payload}
        case SET_VC_FILTER_SELECTED:
            return {...state, filterSelected: action.payload}
        case SET_VC_FILTER_ITEMS:
                return {...state, filterItems: action.payload}            
        case SET_VC_VIEW_TYPE:
            return {...state, graphViewSelected: action.payload}        
        default:
            return state
    }
};
