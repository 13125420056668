/* eslint-disable import/no-anonymous-default-export */
import {
    SET_VIDEO_INDEX,
    SET_NEWS_INDEX,
} from '../actions/_types'

const initialState = {
    newsIndex: [],
    videoIndex: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_VIDEO_INDEX:
            return { ...state, videoIndex: action.payload }
        case SET_NEWS_INDEX:
            return { ...state, newsIndex: action.payload }                                                                  
        default:
            return state
    }
};
