/* eslint-disable import/no-anonymous-default-export */
import {
    SET_CONFIG_ENTITY,
    SET_CONFIG_LAST_PRODUCT_NAME,
    SET_CONFIG_PRODUCT_TYPES,
    SET_CONFIG_PROGRAM_TYPES,
    SET_CONFIG_SELECTED_PROD_TYPE,
    SET_CONFIG_SELECTED_PROG_TYPE,
    SET_CONFIG_LAST_PRODUCT_ID,
    SET_CONFIG_PERSIST_LOAD,
    SET_CONFIG_EFFECTIVE_DATE,
} from '../actions/_types'

const initialState = {
    entity: '',
    lastProduct: '',
    lastProductId: 0,
    productTypes: [],
    programTypes: [],
    lastProductType: {},
    lastProgramType: {},
    persistLoad: false,
    effectiveDate: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CONFIG_ENTITY:
            return {
                ...state,
                entity: action.payload 
            };
        case SET_CONFIG_LAST_PRODUCT_NAME:
            return {
                ...state,
                lastProduct: action.payload 
            };
        case SET_CONFIG_LAST_PRODUCT_ID:
            return {
                ...state,
                lastProductId: action.payload 
            };            
        case SET_CONFIG_PRODUCT_TYPES:
            return {
                ...state,
                productTypes: action.payload 
            };
        case SET_CONFIG_PROGRAM_TYPES:
            return {
                ...state,
                programTypes: action.payload 
            };            
        case SET_CONFIG_SELECTED_PROD_TYPE:
            return {
                ...state,
                lastProductType: action.payload 
            };
        case SET_CONFIG_SELECTED_PROG_TYPE:
            return {
                ...state,
                lastProgramType: action.payload 
            };  
        case SET_CONFIG_PERSIST_LOAD:
            return {
                ...state,
                persistLoad: action.payload
            };
        case SET_CONFIG_EFFECTIVE_DATE:
            return {
                ...state,
                effectiveDate: action.payload
            }                                                           
        default:
            return state
    }
};
