//Estimates a multiplier for grid height based on row count less is more.
const gridHeightCalc = (rowCount = 0) => {
    const rc = rowCount
    switch (true) {
        case (rc === 0): {
            return 0 // 25 for single header row with 'No Rows To Display' message.
        }
        case (rc === 1): {
            return 52
        }
        case (rc === 2): {
            return 80
        }
        case (rc === 3): {
            return rc * 34
        }
        case (rc === 4): {
            return rc * 31.5
        }
        case (rc === 5): {
            return rc * 30.5
        }
        case (rc === 6): {
            return rc * 29.5
        }
        case (rc === 7): {
            return rc * 28.7
        }
        case (rc === 8): {
            return rc * 28.3
        }
        case (rc === 9): {
            return rc * 28
        }
        case (rc === 10): {
            return rc * 27.7
        }        
        case (rc <= 15) && (rc > 10): {
            return rc * 27.35
        }
        case (rc <= 20) && (rc > 15): {
            return rc * 27
        }
        case (rc <= 25) && (rc > 20): {
            return rc * 26.3
        }
        case (rc <= 30) && (rc > 25): {
            return rc * 26
        }
        case (rc <= 35) && (rc > 30): {
            return rc * 25.85
        }
        case (rc <= 40) && (rc > 35): {
            return rc * 25.75
        }
        case (rc <= 45) && (rc > 40): {
            return rc * 25.65
        }
        case (rc <= 50) && (rc > 45): {
            return rc * 25.6
        }
        case (rc <= 100) && (rc > 50): {
            return rc * 25.5
        }
        case (rc <= 500) && (rc > 100): {
            return rc * 25.35
        }                                                  
        default: {
            return rc * 29
        }
    }
}

export default gridHeightCalc