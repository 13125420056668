/* eslint-disable import/no-anonymous-default-export */
import {
    SET_CLIENT_IDLE_STATUS,
    SET_CLIENT_IS_IDLE,
    SET_CLIENT_FORCED_LOGOUT,
    SET_TICK_TOCK,
    SET_LOGOUT_COUNTER,
} from '../actions/_types'

const initialState = {
    isIdle: false,
    forcedLogout: false, // redirect to 'You have been logged out...'
    lastActive: 0, // ms
    timeRemaining: 0, // ms
    tickTock: 0,
    loCount: 0,
    loThreshold: 90, // Minutes
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CLIENT_IDLE_STATUS:
            return { 
                ...state,
                lastActive: initialState.lastActive, 
                timeRemaining: action.timeRemaining
            };
        case SET_CLIENT_IS_IDLE:
            return { 
                ...state, 
                isIdle: action.isIdle,
                lastActive: action.lastActive 
            };
        case SET_CLIENT_FORCED_LOGOUT:
            return { 
                ...state,
                forcedLogout: action.forcedLogout
            };
        case SET_TICK_TOCK:
            return {
                ...state,
                tickTock: action.tickTock
            }
        case SET_LOGOUT_COUNTER:
            return {
                ...state,
                loCount: action.payload
            }                                           
        default:
            return state
    }
};