import { history } from '../lib'

export const navTo = (loc) => {
  history.push(loc);
  return loc
}

export const promiseToNav = async (loc) => {
    return new Promise((resolve) => {
        resolve(history.push(loc))
    })
}
