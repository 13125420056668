/* eslint-disable import/no-anonymous-default-export */
import { 
    VALIDATE_USER, 
    SET_USER_INFO,
    // SET_USER_CONSENT,
    READ_IN_DISTRICTS,
    SET_CUSTOMER_ENTITY,
    SET_USER_SESSION_KEY,
    SET_WHICH_BROWSER,
    SET_EMS_VERSION,
    SET_USER_TIME_TAG,
    SET_USER_IS_NEW,
    SET_USER_REG_PENDING,
    SET_USER_EMS_EMP_ID,
    SET_USER_EMAIL,
    SET_INIT_USER_OFFICE,
    SET_USER_REGISTERED,
    SET_VALID_USER_LOGGED_IN,
    //
    SET_USER_EDIPI, 
    SET_USER_NAME_STRING, 
    SET_USER_ROLES,
    SET_USER_LAST_LOGIN, 
} from '../actions/_types'

const initialState = {
    isValid: false,
    isNew: false,
    regPending: false,
    active: false,
    userID: null, // Loaded from search
    edipi: null, // Set from Auth Actions
    userString: null, // Set from Auth Actions
    email: null,
    roles: [], // Set from user Auth
    userSessionID: -1, // Wil be set from Auth
    userOffice: null, // Only used for initial roles, does not replace entity which aligns with product
    entity: null, // Not referenced, using root component
    domain: null,
    districtList: [], // Read in / parse from org
    token: null,
   // consent: false, // DoD Warning Banner, seen and acknowledged
    hasError: false,
    error: null,
    browser: {},
    emsVersion: '0.0.0',
    tag: 0,
    lastLogin: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case VALIDATE_USER:
            return { ...state, isValid: action.payload };
        case SET_USER_INFO:
            return { ...state, action };
        // case SET_USER_CONSENT:
        //     return { ...state, consent: action.consent };                         
        case READ_IN_DISTRICTS:
            return { ...state, districtList: action.payload };
        case SET_CUSTOMER_ENTITY:
            return { ...state, entity: action.payload };
        case SET_USER_SESSION_KEY:
            return { ...state, userSessionID: action.payload };
        case SET_WHICH_BROWSER:
            return { ...state, browser: action.payload };                                            
        case SET_USER_EDIPI:
            return { ...state, edipi: action.payload };  
        case SET_USER_NAME_STRING:
            return { ...state, userString: action.payload };
        case SET_USER_ROLES:
            return { ...state, roles: action.payload };
        case SET_EMS_VERSION:
            return { ...state, emsVersion: action.payload };
        case SET_USER_TIME_TAG:
            return { ...state, tag: action.payload };
        case SET_USER_IS_NEW:
            return { ...state, isNew: action.payload};  
        case SET_USER_REG_PENDING:
            return { ...state, regPending: action.payload};
        case SET_USER_EMS_EMP_ID:
            return { ...state, userID: action.payload};
        case SET_USER_EMAIL:
            return { ...state, email: action.payload};
        case SET_INIT_USER_OFFICE:
            return { ...state, userOffice: action.payload};
        case SET_USER_REGISTERED:
            return { ...state, active: action.payload};
        case SET_USER_LAST_LOGIN:
            return {...state, lastLogin: action.payload};                         
        case SET_VALID_USER_LOGGED_IN:
            return {
                ...state,
                // ...action.payload
                regPending: action.payload.regPending,
                active: action.payload.active,
                userID: action.payload.userID,
                edipi: action.payload.edipi,
                userString: action.payload.userString,
                email: action.payload.email,
                userOffice: action.payload.userOffice,
                token: action.payload.token
            }
        default:
            return state
    }
};

