
/* eslint-disable import/no-anonymous-default-export */
import {
    SET_PRODUCT_LIST,
    SET_SELECTED_PRODUCT,
    SET_PRODUCT_UPDATE,
    SET_PRODUCT_LOCKED,
    SET_PRODUCT_DATES,
    SET_STATUS_DATE,
    SET_PRODUCT_TYPE,
    STORE_PRODUCT_TYPE_Z,
    SET_PROGRAM_TYPE,
    STORE_PROGRAM_TYPE_Z,
    SET_PEP_INFO,
    SET_P2_LOAD,
    SET_SHOW_P2_CHARGE_CODES,
    SET_BASIN_LOAD,
    SET_LOCATION_LOAD,
    FLUSH_PRODUCT_INFO,
    FLUSH_PRODUCT,
    FLUSH_PRODUCT_SELECT,
    SET_START_DATE,
    SET_INC_REPORTING,
    SET_RESOURCE_UPDATED,
    SET_PEP_TOGGLE,
    SET_PRODUCT_CURRENT_VERSION,
    SET_PRODUCT_REV_DATE,
    SET_PRODUCT_NOTES,
    SET_PRODUCT_LIKELINESS,
    SET_PRODUCT_SITREP,
    SET_P2_ROWS,
    SET_P2_COLS,
    SET_PARSE_COMPLETE,
    SET_PRAC_DID_UPDATE,
    SET_WI_CHANGE_ARRAY,
    SET_WI_CHANGE_ORDER,
} from '../actions/_types'

const initialState = {
    productID: null,
    productTitle: null,
    fcst: false,
    fcstLikeliness: null, 
    revDate: null,
    currentVersion: null,
    productList: null,
    productType: null,
    productTypes: [],
    programType: null,
    programTypes: [],
    createDate: null,
    startDate: null,
    statusDate: null, // Product statusing storage
    updateSelect: false,
    incReporting: true,
    resourceUpdated: false,
    pepNotes: {
        productId: null,
        background: '',
        productDescription: '',
        assumptions: '',
        changeManagement: '',
        safetyPlan: '',
        riskManagement: '',
        communications: '',
        valueManagement: '',
        dataManagement: '',
        qualityManagement: '',
        closeOut: '',
        versionRemarks: '',
        loaded: false,
    },
    productNotes: null,
    productSitRep: null,
    PEP: false,
    locked: false,
    p2Numbers: {},
    p2Cols: [],
    p2Rows: [],
    pracDidUpdate: false,
    parseComplete: null,
    showP2ChargeCode: false,
    locations: {},
    isNew: false,
    isValid: true,
    hasError: false,
    error: null,
    wiChange: [],
    wiChangeOrder: 0,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_PRODUCT_LIST:
            return { 
                ...state, 
                productList: action.payload 
            };
        case SET_SELECTED_PRODUCT:
            return {
                ...state,
                productID: action.payload.ID,
                productTitle: action.payload.Title,
                fcst: action.payload.fcst,
                fcstLikeliness: action.payload.fcstLikeliness,
                currentVersion: action.payload.currentVersion,
                revDate: action.payload.revDate,
                locked: action.payload.locked,
                updateSelect: action.update,
            };
        case SET_PRODUCT_UPDATE:
            return {
                ...state,
                updateSelect: action.update
            }
        case SET_PRODUCT_DATES:
            return {
                ...state,
                createDate: action.createDate,
                startDate: action.startDate
            }            
        case SET_STATUS_DATE:
            return {
                ...state,
                statusDate: action.statusDate
            }              
        case SET_PRODUCT_LOCKED:
            return {
                ...state,
                locked: action.locked
            }            
        case SET_P2_LOAD:
            return {
                ...state,
                p2Numbers: action.payload
            }
        case SET_BASIN_LOAD:
            return {
                ...state,
                basins: action.payload
            }
        case SET_LOCATION_LOAD:
            return {
                ...state,
                locations: action.payload
            }            
        case SET_PRODUCT_TYPE:
            return {
                ...state,
                productType: action.payload
            }
        case SET_PROGRAM_TYPE:
            return {
                ...state,
                programType: action.payload
            }
        case SET_PEP_INFO:
            return {
                ...state,
                pepNotes: action.payload
            }
        case SET_PEP_TOGGLE:
            return {
                ...state,
                PEP: action.payload
            }            
        case SET_INC_REPORTING:
            return {
                ...state,
                incReporting: action.payload
            }
        case SET_START_DATE:
            return {
                ...state,
                startDate: action.payload
            }           
        case SET_RESOURCE_UPDATED:
            return {
                ...state,
                resourceUpdated: action.payload
            }
                                                               
        case FLUSH_PRODUCT_INFO:
            return {
                ...state,
                productType: action.productType,
                programType: action.programType,
                createDate: action.createDate,
                startDate: action.startDate,
                updateSelect: action.updateSelect, // Doesn't include pepNotes.
                resourceUpdated: action.updated, // force grids to reload fresh.
            }
        case FLUSH_PRODUCT:
            return {
                ...state,
                ...initialState
            }
        case FLUSH_PRODUCT_SELECT:
            return {
                ...state,
                productList: [{label: action.payload.label, key: action.payload.key, value: action.payload.value}]
            }
        case STORE_PROGRAM_TYPE_Z:
            return {
                ...state,
                programTypes: action.programTypes
            }
        case STORE_PRODUCT_TYPE_Z:
            return {
                ...state,
                productTypes: action.productTypes
            }
        case SET_PRODUCT_CURRENT_VERSION:
            return {
                ...state,
                currentVersion: action.currentVersion
            }
        case SET_PRODUCT_REV_DATE:
            return {
                ...state,
                revDate: action.revDate
            }
        case SET_PRODUCT_NOTES:
            return {
                ...state,
                productNotes: action.productNotes
            }
        case SET_PRODUCT_SITREP:
            return {
                ...state,
                productSitRep: action.productSitRep
            }            
        case SET_PRODUCT_LIKELINESS:
            return {
                ...state,
                fcstLikeliness: action.fcstLikeliness
            }
        case SET_SHOW_P2_CHARGE_CODES:
            return {
                ...state,
                showP2ChargeCode: action.showP2ChargeCode
            }
        case SET_P2_ROWS:
            return {
                ...state,
                p2Rows: action.p2Rows
            }  
        case SET_P2_COLS:
            return {
                ...state,
                p2Cols: action.p2Cols
            }
        case SET_PARSE_COMPLETE:
            return {
                ...state,
                parseComplete: action.complete
            }
        case SET_PRAC_DID_UPDATE:
            return {
                ...state,
                pracDidUpdate: action.update
            }
        case SET_WI_CHANGE_ARRAY:
            return {
                ...state,
                wiChange: action.change
            } 
        case SET_WI_CHANGE_ORDER: 
            return {
                ...state,
                wiChangeOrder: action.order
            }                                                                                                        
        default:
            return {
                ...state
            }
    }
};
