/* eslint-disable import/no-anonymous-default-export */
import typeToReducer from 'type-to-reducer';
import {
    FETCH_CREDENTIALS,
    FETCH_SIMPLE, 
    FETCH_API_DATA, 
    FETCH_API_SIMPLE,
    SEND_API_DATA, 
    SEND_API_PRODUCT_DATA,
    FETCH_SESSION_DATA,
    FETCH_API_SIMPLE_CANCELLABLE,
    POST_KEYCLOAK_CREDENTIALS,
} from '../actions/_types';

const initialState = {
    data: null,
    isPending: false,
    isRejected:  false,
    isFulfilled: false,
    hasError: false,
    error: null,
}

export const reducer = typeToReducer({
    [ POST_KEYCLOAK_CREDENTIALS ]: {
        PENDING: (state) => ({
            ...state,
            isPending: true,
        }),
        REJECTED: (state, action) => ({
            ...state,
            isPending: false,
            isRejected: true,
            hasError: true,
            error: action.payload,
        }),
        FULFILLED: (state = [], action) => ({
            ...state,
            isPending: false,
            isFulfilled: true,
            data: action.payload,
        })
    },
    [ FETCH_CREDENTIALS ]: {
        PENDING: (state) => ({
            ...state,
            isPending: true,
        }),
        REJECTED: (state, action) => ({
            ...state,
            isPending: false,
            isRejected: true,
            hasError: true,
            error: action.payload,
        }),
        FULFILLED: (state = [], action) => ({
            ...state,
            isPending: false,
            isFulfilled: true,
            data: action.payload,
        })
    },
    [ FETCH_SIMPLE ]: {
        PENDING: (state) => ({
            ...state,
            isPending: true,
        }),
        REJECTED: (state, action) => ({
            ...state,
            isPending: false,
            isRejected: true,
            hasError: true,
            error: action.payload,
        }),
        FULFILLED: (state = [], action) => ({
            ...state,
            isPending: false,
            isFulfilled: true,
            data: action.payload,
        })
    },
    [ FETCH_API_DATA ]: {
        PENDING: (state) => ({
            ...state,
            isPending: true,
        }),
        REJECTED: (state, action) => ({
            ...state,
            isPending: false,
            isRejected: true,
            hasError: true,
            error: action.payload,
        }),
        FULFILLED: (state = [], action) => ({
            ...state,
            isPending: false,
            isFulfilled: true,
            data: action.payload,
        })
    },
    [ FETCH_API_SIMPLE ]: {
        PENDING: (state) => ({
            ...state,
            isPending: true,
        }),
        REJECTED: (state, action) => ({
            ...state,
            isPending: false,
            isRejected: true,
            hasError: true,
            error: action.payload,
        }),
        FULFILLED: (state = [], action) => ({
            ...state,
            isPending: false,
            isFulfilled: true,
            data: action.payload,
        })
    },
    [ FETCH_API_SIMPLE_CANCELLABLE ]: {
        PENDING: (state) => ({
            ...state,
            isPending: true,
        }),
        REJECTED: (state, action) => ({
            ...state,
            isPending: false,
            isRejected: true,
            hasError: true,
            error: action.payload,
        }),
        FULFILLED: (state = [], action) => ({
            ...state,
            isPending: false,
            isFulfilled: true,
            data: action.payload,
        })
    },
    [ SEND_API_DATA ]: {
        PENDING: (state) => ({
            ...state,
            isPending: true,
        }),
        REJECTED: (state, action) => ({
            ...state,
            isPending: false,
            isRejected: true,
            hasError: true,
            error: action.payload,
        }),
        FULFILLED: (state = [], action) => ({
            ...state,
            isPending: false,
            isFulfilled: true,
            data: action.payload,
        })
    },
    [ SEND_API_PRODUCT_DATA ]: {
        PENDING: (state) => ({
            ...state,
            isPending: true,
        }),
        REJECTED: (state, action) => ({
            ...state,
            isPending: false,
            isRejected: true,
            hasError: true,
            error: action.payload,
        }),
        FULFILLED: (state = [], action) => ({
            ...state,
            isPending: false,
            isFulfilled: true,
            data: action.payload,
        })
    },
    [ FETCH_SESSION_DATA ]: {
        PENDING: (state) => ({
            ...state,
            isPending: true,
        }),
        REJECTED: (state, action) => ({
            ...state,
            isPending: false,
            isRejected: true,
            hasError: true,
            error: action.payload,
        }),
        FULFILLED: (state = [], action) => ({
            ...state,
            isPending: false,
            isFulfilled: true,
            data: action.payload,
        })
    },           
}, initialState)

export default reducer;
