import { toLower } from 'lodash'

// Used for local and session storage t/f read-ins

const strToBool = (str = '') => {
    switch(toLower(str)) {
        case 'true' : {
            return true
        }
        case 'false' : {
            return false
        }
        case 't': {
            return true
        }
        case 'f': {
            return false
        }
        default: {
            return false
        }
    }
}

export default strToBool