export const actionMap = { 
    set_cache_sys_version: ['version'],
    set_cache_user_entity: ['org'],
    // basic controls
    set_search_type: ['controls'],
    set_doh_mode: ['controls'],
    set_grid_or_graph: ['controls'],
    store_sections: ['controls'],
    store_sections_raw: ['controls'],
}

export default actionMap