
import {
    keysIn,
    startCase,
    forOwn,
    isEmpty,
    indexOf,
    split,
    pullAll,
    each,
    remove,
    find,
    replace,
    uniq,
    toUpper,
    flatten,
    endsWith,
    capitalize,
    has,
    map, 
    } from 'lodash'

const filterReportColumns = (response = {}, readInSections = [], incSection = false, editable = false, alt = false) => {
    // Add items to this list that you do not want displayed, items must also have a column definition below.
    const filter = [
        /* Report */
        'status',
        'searchName',
        'productId',
        'pdtMember',
        'officeCode',
        'performingOrg',
        'p2Number',
        'null',
        'fy',
        'code',
        'code_2',
        'code_3',
        'type',
        'rootProductId',
        'deliverableNumber',
        'taskNumber',
        'subtaskNumber',
        'fcst',
        'isExcluded',
        'currentVersion',
        'scopeVersionDate',
        'lockStatus',
        'lockDate',
        'lockBy',

    ] // eslint-disable-line
    // Used for comparison on section columns, need to make this more efficient eventually.
    const chkOrg = has(readInSections[0], 'orgCode')
    const sectionAndOrgs = (chkOrg) ? readInSections : []
    const sectionList = (chkOrg) ? map(readInSections, 'sectionCode') : readInSections
    const districtList = isDistrict(sectionList) // TODO: Try to future proof the comparison
    
    // Gather header names
    // Check to see if headers are seperated from row-data where header key will equal row-data field_name
    // Not full map included in header, will use as a lookup.
    let empIdHeader = []
    if(response.data.Header){ 
        empIdHeader = response.data.Header
    }

    // TODO: Discovered object keys with 'undefined' as the value get removed by itterator, crap!

   let inKeys = []
    if(response.data.Data) {
        // inKeys = keys(response.data.Data[0])
        inKeys = keysIn(response.data.Data[0])
    } else {
        //inKeys = keys(response.data[0])
        inKeys = keysIn(response.data[0])
    }
    // Try to track group / child creation blindly
    let tmpCnt = 0
    let tmpLastGroupName = ''
    let tmpGroupHolder = [] // Now not blindly
    let estWidth = 0

    const columnNames = inKeys.map(inName => { // eslint-disable-line
        const strCaseHeaderName = startCase(inName)
        const doHide = (filter.indexOf(inName) > -1) // If columnname exists in the filter array mark hide = true
    // Column definitions
        switch (inName) {
            case 'currentVersion': {
                return ({headerName: strCaseHeaderName, field: inName, hide: doHide})
            }
            case 'scopeVersionDate': {
                return ({headerName: strCaseHeaderName, field: inName, hide: doHide})
            }
            case 'null': {
                return ({headerName: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }
            case 'rootProductId': {
                return ({headerName: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }        
            case 'deliverableNumber': {
                return ({headerName: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }        
            case 'taskNumber': {
                return ({headerName: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }        
            case 'subtaskNumber': {
                return ({headerName: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }        
            case 'type': {
                return ({headerName: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }
            case 'role': {
                return ({
                    headerName: (alt) ? 'Designator' : strCaseHeaderName, 
                    field: inName, 
                    width: 110, 
                    hide: doHide
                }) // Designator
            }
            case 'code': {
                return ({headerName: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }
            case 'code_2': {
                return ({headerName: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }
            case 'code_3': {
                return ({headerName: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }
            case 'status': {
                return ({headerName: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }
            case 'lockStatus': {
                return ({headerName: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }
            case 'lockDate': {
                return ({headerName: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }
            case 'lockBy': {
                return ({headerName: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }
            case 'productId': {
                return ({headerName: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }
            case 'wbs': {
                return ({headerName: 'WBS', field: inName, width: 75, hide: doHide})
            }
            case 'productTitle': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 500, 
                    hide: doHide,
                    suppressSizeToFit: true,
                    cellStyle: {
                        textAlign: 'left'
                    },
                })
            }
            case 'title': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 500, 
                    hide: doHide,
                    suppressSizeToFit: true,
                    cellStyle: {
                        textAlign: 'left'
                    },
                })
            }            
            case 'contractName': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 300, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'lineItem': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 300, 
                    hide: doHide,
                    editable: false,
                    suppressSizeToFit: true,
                    cellStyle: {
                        textAlign: 'left'
                    }
                })
            }             
            case 'searchName': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 225, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'pdtMember': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 225, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }                                    
            case 'programType': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'fundingType': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'productType': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'productPhase': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'projectManager': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 250, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'technicalLead': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 300, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'reviewer': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 250, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'cor': {
                return ({
                    headerName: 'COR', 
                    field: inName, 
                    width: 250, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'aco': {
                return ({
                    headerName: 'ACO', 
                    field: inName, 
                    width: 250, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'pdt': {
                return ({
                    headerName: 'PDT', 
                    field: inName, 
                    width: 250, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'isExcluded': {
                return ({
                    headerName: 'Reporting', 
                    field: inName, 
                    width: 100, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'fcst': {
                return ({
                    headerName: 'Forecasted', 
                    field: inName, 
                    width: 100, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }                                                                                                                                                                       
            case 'p2Number': {
                return ({
                    headerName: 'P2 Number', 
                    field: inName, 
                    width: 130, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'owi': {
                return ({
                    headerName: 'Work Item', 
                    field: inName, 
                    width: 500, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'chargeCode': {
                return ({
                    headerName: 'Charge Code', 
                    field: inName, 
                    width: 130, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'performingOrg': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 250, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'nextMilestone': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 250, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'milestoneDate': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 120, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }                         
            case 'fy': {
                return ({
                    headerName: 'FY', 
                    field: inName, 
                    width: 60, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'officeCode': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'orgCode': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'total': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'startDate': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 120, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }  
            case 'endDate': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 120, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            } 
            case 'lastStatusDate': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 120, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'productManagementDate': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 120, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'earnedValue': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }  
            case 'statusPercentComplete': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }  
            case 'plannedToStatusDate': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'plannedPercentComplete': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'actualPercentComplete': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'actualToStatusDate': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }            
            case 'nextMileStone': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'sitrep': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 750, 
                    hide: doHide,
                    // editable: editable
                    editable: true,
                    suppressSizeToFit: true,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        readOnly: true, // not really effective
                        maxLength: 0, // doesnt' stop blank editors from entering characters 
                        cols: 80,
                        rows: 20,
                    }
                })
            }  

            case 'sitrepDate': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 120, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            } 
            case 'locations': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName, 
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'productManagement': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName, 
                    field: inName, 
                    width: 250, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }  
            case 'sectionCode': {
                return ({
                    headerName: strCaseHeaderName, 
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'sectionCodeTl': {
                return ({
                    headerName: (alt) ? 'TL Section Code' : strCaseHeaderName, 
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'empName': {
                return ({
                    headerName: 'Employee', 
                    field: inName, 
                    width: 200, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }                                                                                                                                                                                                                                           
            default: {
                let tmpInName = inName 
                forOwn(empIdHeader, (value, key) => {
                    if(inName === key) { 
                        return inName = value
                    }
                })

                // if (endsWith(inName, '_20', 6)) { // Capitalize Month_Year (Apr_2020 etc)
                //   //  tmpInName = capitalize(inName)
                // }

                const tmpIsOs = isOfficeSymbol(inName, districtList) // 

                if(tmpIsOs.isbool && incSection === true) {

                        let tmpSplitNames = split(inName, ' ', 10)

                         forOwn(tmpSplitNames, (value, key) => {
                            (isEmpty(value)) ? tmpSplitNames[key] = '' : tmpSplitNames[key] = value
                        })
                        
                        if(indexOf(tmpSplitNames[0], '-') === -1) {
                            if(tmpIsOs.group !== tmpSplitNames[0]) {
                                tmpSplitNames[0] = tmpIsOs.group
                            }
                            let tmpIdx = split(tmpIsOs.group, ' ')
                            tmpSplitNames = pullAll(tmpSplitNames, tmpIdx)                        
                        }

                        let tmpHeaderName = ''
                        let ctrlColGroupShow = 'open'
                        let tmpStructure = {}                        
                    
                        if(tmpLastGroupName !== tmpSplitNames[0]) { 

                            if(!isEmpty(tmpLastGroupName) && 
                                (tmpLastGroupName !== tmpSplitNames[0]) && 
                                (tmpGroupHolder.indexOf(tmpSplitNames[0]) === -1)) { 
                                    tmpCnt = 0
                            }                            
                            tmpLastGroupName = tmpSplitNames[0]
                        }                
                        tmpCnt++
                        if(tmpCnt === 1 && tmpGroupHolder.indexOf(tmpSplitNames[0] === -1)) {
                            tmpGroupHolder.push(tmpSplitNames[0]) 
                            tmpHeaderName = estHeader(sectionList, tmpSplitNames)
                            let estWidth
                            (tmpSplitNames[0].length > tmpHeaderName.length) ? estWidth = estColLength(`${tmpSplitNames[0]}`) : estWidth = estColLength(`${tmpHeaderName}`)
                            tmpStructure = {
                                headerName: tmpSplitNames[0],
                                groupId: tmpSplitNames[0],
                                openByDefault: true,
                                marryChildren: true,
                                width: estColLength(`${tmpSplitNames[0]}`),
                                children: [{
                                    joinOn: tmpSplitNames[0], 
                                    headerName: `${tmpHeaderName}`, 
                                    field: tmpInName,
                                    width: estWidth,
                                    editable: editable,
                                    hide: false,
                                    cellStyle: {
                                        textAlign: 'center' 
                                    } 
                                }]
                            }
                        } else {
                            tmpHeaderName = estHeader(sectionList, tmpSplitNames)
                            estWidth = estColLength(`${tmpHeaderName}`)
                            tmpStructure = {
                                joinOn: tmpSplitNames[0],                                    
                                headerName: `${tmpHeaderName}`,
                                field: tmpInName,
                                width: estWidth,
                                editable: editable,
                                hide: false,
                                suppressSizeToFit: true,
                                columnGroupShow: `${ctrlColGroupShow}`,
                                filter: 'agNumberColumnFilter', 
                                cellStyle: {
                                    textAlign: 'center' 
                                },
                            }
                        }
                        return tmpStructure                            
                    //}
                } else {
                    const tmpIsOC =  isOfficeCode(sectionList, tmpInName, sectionAndOrgs, chkOrg)
                    estWidth = estColLength(`${tmpIsOC}`, false)
                    return ({
                        headerName: tmpIsOC, 
                        field: tmpInName,
                        width: estWidth,
                        editable: editable,
                        hide: false,
                    }) 
                }
            }       
        }
    });
    if(incSection === true) {
        return processSections(columnNames, sectionAndOrgs, chkOrg)
    } else {
        return columnNames
    }
}

const processSections = (columnDefs, sectionAndOrgs = [], chkOrg = false) => {
    let tmpDefChildren = []
    each(columnDefs, (colValue) => {
        if (isEmpty(colValue.children) && !isEmpty(colValue.joinOn)) {
            tmpDefChildren.push(colValue)       
        }
    })
    remove(columnDefs, (tmpDefChildren) => {
        return (columnDefs.joinOn !== tmpDefChildren.joinOn)
    })
    each(tmpDefChildren, (tmpValue) => {
        find(columnDefs, (obj) => {
            if (obj.groupId === tmpValue.joinOn) {
                return obj.children.push(tmpValue)
            }
        })
    })

    if(chkOrg && has(sectionAndOrgs[0], 'orgCode')) {
        each(columnDefs, (value) => {
            find(sectionAndOrgs, (obj) => {
                if(obj.sectionCode === value.headerName) {
                    return value.headerName = `${value.headerName} : ${obj.orgCode}`
                }
            })
        })
    }

    return columnDefs
}

const estColLength = (inFieldName = '') => {
    const inLen = inFieldName.length
    switch (true) {
        case (inLen <= 3): {
            return inLen * 24
        }
        case (inLen <= 5) && (inLen > 3): {
            return inLen * 22
        }
        case (inLen <= 10) && (inLen > 5): {
            return inLen * 18
        }
        case (inLen <= 15) && (inLen > 10): {
            return inLen * 16
        }       
        case (inLen <= 20) && (inLen > 15): {
           return inLen * 15 
        }    
        case (inLen <= 30) && (inLen > 20): {
           return inLen * 15
        }
        default: {
            return inLen * 12
        }
    }
}

const estHeader = (sectionList, tmpSplitNames) => {
    let tmpHeaderName = ''
    if(sectionList.indexOf(tmpSplitNames[3]) > -1) {
    return `${tmpSplitNames[1]} ${replace(tmpSplitNames[2], ',', '')}` 
    } else {
        forOwn(tmpSplitNames, (value, key) => {
            if (!isEmpty(value) && key > 0) {
                tmpHeaderName += `${tmpSplitNames[key]} `
            }
        })
    }
    return tmpHeaderName
}

const isOfficeCode = (sectionList, inHeader = '', sectionAndOrgs = [], chkOrg = false) => {
    let tmpOfficeCode = ''
    let tmpDistrict  = []
    let outHeader = inHeader

    each(sectionList, (value) => {
        tmpDistrict.push(split(value, '-', 1))
    })
    tmpDistrict = uniq(flatten(tmpDistrict))
    tmpOfficeCode = replace(toUpper(startCase(inHeader)), new RegExp('[ ]', 'g'), '-')

    if(indexOf(tmpDistrict, split(tmpOfficeCode, '-', 1)[0]) > -1){
        outHeader = tmpOfficeCode
    }
    const testValue = replace(toUpper(startCase(tmpOfficeCode)), new RegExp('[-]', 'g'), ' ')
    if(indexOf(tmpDistrict, testValue) > -1) {
        outHeader = testValue
    }

    // TODO: Put this someplace else eventually.
    if (endsWith(inHeader, '_20', 6)) { //
        outHeader = replace(capitalize(inHeader), new RegExp('[_]', 'g'), ' ')
    }

    if(chkOrg && has(sectionAndOrgs[0], 'orgCode')) {
        find(sectionAndOrgs, (obj) => {
            if(obj.sectionCode === outHeader) {
                return outHeader = `${outHeader} : ${obj.orgCode}`
            }
        })
    }

    return outHeader 
}

const isOfficeSymbol = (inName = '', inDistricts = []) => {
    let tmpDist = []
    const tmpInColPart = split(toUpper(startCase(inName)), ' ', 1)
    
    if(indexOf(inDistricts, tmpInColPart[0]) === -1) {
        each(inDistricts, (value) => { 
            tmpDist = split(value, ' ')
            if(tmpDist[0] === tmpInColPart[0]) {
                each(tmpDist, (value, key) => {
                    if(key === 0) {
                        tmpInColPart[0] = value
                    } else {
                        tmpInColPart[0] += ` ${value}`
                    }
                })
            }
        })
    }
    return (indexOf(inDistricts, tmpInColPart[0]) > -1) ? {isbool: true, group: tmpInColPart[0]} : {isbool: false, group: tmpInColPart[0]}

}

const isDistrict = (inSections = []) => {
    let tmpDistrict = []
    each(inSections, (value) => {
        tmpDistrict.push(split(value, '-', 1))
    })
    tmpDistrict = uniq(flatten(tmpDistrict))
    return tmpDistrict
}

export default filterReportColumns
