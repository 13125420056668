
/* eslint-disable import/no-anonymous-default-export */
import { 
    SET_SEARCH_TYPE,
    SET_DOH_MODE,
    SET_GRID_OR_GRAPH,
    STORE_SECTIONS,
    STORE_SECTIONS_RAW,
    STORE_ORG_SELECT,
    SET_DAYS_TYPE,
    SET_EXPAND_P2,
    SET_SCHEDULE_AUTO_REFRESH,
} from '../actions/_types'

const initialState = {
    searchType: {},
    sections: [],
    sectionCodes: [],
    dohMode: 'HOURS',
    dayType: 'WD',
    gridOrGraph: 'GRID',
    orgSelect: [],
    expandP2: null,
    recalc: true,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SEARCH_TYPE:
            return { ...state, searchType: action.payload }                          
        case SET_DOH_MODE:
            return { ...state, dohMode: action.dohMode }
        case SET_DAYS_TYPE:
            return { ...state, dayType: action.dayType }            
        case SET_GRID_OR_GRAPH:
            return { ...state, gridOrGraph: action.gridOrGraph }
        case STORE_SECTIONS:
            return { ...state, sections: action.sections }
        case STORE_SECTIONS_RAW:
            return { ...state, sectionCodes: action.sectionCodes }
        case STORE_ORG_SELECT:
            return { ...state, orgSelect: action.orgSelect }                                             
        case SET_EXPAND_P2:
            return { ...state, expandP2: action.expandP2 } 
        case SET_SCHEDULE_AUTO_REFRESH:
            return { ...state, recalc: action.recalc }             
        default:
            return state
    }
};