// Action constants
// Query Actions
export const FETCH_CREDENTIALS = 'fetch_credentials'
export const POST_KEYCLOAK_CREDENTIALS = 'post_keycloak_credentials'
export const FETCH_SIMPLE = 'fetch_simple'
export const FETCH_API_DATA = 'fetch_api_data'
export const FETCH_API_SIMPLE = 'fetch_api_simple'
export const SEND_API_DATA = 'send_api_data'
export const SEND_API_PRODUCT_DATA = 'send_api_product_data'
export const FETCH_SESSION_DATA = 'fetch_session_data'
export const FETCH_API_SIMPLE_CANCELLABLE = 'fetch_api_simple_cancellable'

// AG-GRID
export const ROW_DATA_CHANGED = 'row_data_changed'
export const ROW_SELECTION_CHANGED = 'row_selection_changed'
export const AG_GRID_PASS_THROUGH = 'ag_grid_pass_through'
// Push Columns and Rows to global state for reference,
// We'll see if this becomes too much overhead
export const STORE_SCOPE_ROW_DATA = 'store_scope_row_data'
export const STORE_SCOPE_COL_DEFS = 'store_scope_col_defs'
export const STORE_STATUS_ROW_DATA = 'store_status_row_data'
export const STORE_STATUS_ORIGIN_DATA = 'store_status_origin_data'
export const STORE_STATUS_COL_DEFS = 'store_status_col_defs'
export const STORE_BUDGET_ROW_DATA = 'store_budget_row_data'
export const STORE_BUDGET_COL_DEFS = 'store_budget_col_defs'
export const STORE_COMMITMENT_ROW_DATA = 'store_commitment_row_data'
export const STORE_COMMITMENT_COL_DEFS = 'store_commitment_col_defs'
export const STORE_PDT_ROW_DATA = 'store_pdt_row_data'
export const STORE_PDT_COL_DEFS = 'store_pdt_col_defs'
export const STORE_MISC_COL_DEFS = 'store_misc_col_defs'
export const STORE_MISC_ROW_DATA = 'store_misc_row_data'
export const STORE_OUTSRC_COL_DEFS = 'store_outsrc_col_defs'
export const STORE_OUTSRC_ROW_DATA = 'store_outsrc_row_data'
export const FLUSH_STORED_GRID_INFO = 'flush_stored_grid_info'
export const STORE_MS_COL_DEFS = 'store_ms_col_defs'
export const STORE_MS_ROW_DATA = 'store_ms_row_data'

// Navigation
export const PUSH_LOCATION = 'push_location'
export const SET_CURRENT_HREF = 'set_current_href'
export const SET_CURRENT_LOC_HASH = 'set_current_loc_hash'
export const SET_CURRENT_LOC_SEARCH = 'set_current_loc_search'
export const SET_CURRENT_LOC_ALL = 'set_current_loc_all'

// User
export const VALIDATE_USER = 'validate_user'
export const SET_USER_INFO = 'set_user_info'
// export const SET_USER_CONSENT = 'set_user_consent'
export const READ_IN_DISTRICTS = 'read_in_districts'
export const SET_CUSTOMER_ENTITY = 'set_customer_entity'
export const SET_USER_SESSION_KEY = 'set_user_session_key'
export const SET_WHICH_BROWSER = 'set_which_browser'
export const SET_EMS_VERSION = 'set_ems_version'
export const SET_USER_TIME_TAG = 'set_user_time_tag'
export const SET_USER_IS_NEW = 'set_user_is_new'
//
export const SET_USER_EDIPI = 'set_user_edipi'
export const SET_USER_NAME_STRING = 'set_user_name_string'
export const SET_USER_ROLES = 'set_user_roles'
export const SET_USER_EMS_EMP_ID = 'set_user_ems_emp_id'
export const SET_USER_REG_PENDING = 'set_user_reg_pending'
export const SET_USER_EMAIL = 'set_user_email'
export const SET_INIT_USER_OFFICE = 'set_initial_user_office'
export const SET_USER_REGISTERED = 'set_user_registered'
export const SET_VALID_USER_LOGGED_IN = 'set_valid_user_logged_in'
//
export const SET_USER_LAST_LOGIN = 'set_user_last_login'

// Auth
export const SET_AUTH_LOGIN = 'set_auth_login'
export const SET_AUTH_LOGOUT = 'set_auth_logout'
export const SET_AUTH_STATUS = 'set_auth_status'
export const SET_REVALIDATE = 'set_revalidate'
export const SET_AUTH_REATTEMPTS = 'set_auth_reattempts'
export const SET_AUTH_VALIDATE_ACTION = 'set_auth_validate_action'
//
export const SET_AUTH_MANUAL_LOGOUT = 'set_auth_manual_logout'
export const SET_AUTH_MANUAL_LOGIN = 'set_auth_manual_login'
export const SET_AUTH_PRE_AUTH = 'set_auth_pre_auth'
export const SET_AUTH_QUERY_IS_PENDING = 'set_auth_query_is_pending'

// Message
export const SET_BROADCAST_MESSAGE = 'set_broadcast_message'
export const SET_MESSAGE = 'set_message'
export const CLEAR_MESSAGE = 'clear_message'
export const CLEAR_MESSAGE_Z = 'clear_message_queue'
export const SET_MESSAGE_TO_QUEUE = 'set_message_to_queue'

// Product
export const SET_PRODUCT_LIST = 'set_product_list'
export const SET_SELECTED_PRODUCT = 'set_selected_product'
export const SET_PRODUCT_UPDATE = 'set_product_update'
export const SET_PRODUCT_LOCKED = 'set_product_locked'
export const SET_PRODUCT_DATES = 'set_product_dates'
export const SET_STATUS_DATE = 'set_status_date'
export const FLUSH_PRODUCT_INFO = 'flush_product_info'
export const FLUSH_PRODUCT = 'flush_product'
export const FLUSH_PRODUCT_SELECT = 'flush_product_select'
export const SET_INC_REPORTING = 'set_inc_reporting'
export const SET_START_DATE = 'set_start_date'
export const SET_RESOURCE_UPDATED = 'set_resource_updated'
export const SET_PRODUCT_NOTES = 'set_product_notes'
//
export const SET_P2_LOAD = 'set_p2_load'
export const SET_SHOW_P2_CHARGE_CODES = 'set_show_p2_charge_codes'
export const SET_P2_ROWS = 'set_p2_rows'
export const SET_P2_COLS = 'set_p2_cols'
export const SET_PARSE_COMPLETE = 'set_parse_complete'
export const SET_BASIN_LOAD = 'set_basin_load'
export const SET_LOCATION_LOAD = 'set_location_load'
export const SET_PROGRAM_TYPE = 'set_program_type'
export const STORE_PROGRAM_TYPE_Z = 'set_program_type_z'
export const SET_PRODUCT_TYPE = 'set_product_type'
export const STORE_PRODUCT_TYPE_Z = 'set_product_type_z'
export const SET_PEP_INFO = 'set_pep_info'
export const SET_PEP_TOGGLE = 'set_pep_toggle'
export const SET_PRODUCT_CURRENT_VERSION = 'set_product_current_version'
export const SET_PRODUCT_REV_DATE = 'set_product_rev_date'
export const SET_PRODUCT_LIKELINESS = 'set_product_likeliness'
export const SET_PRODUCT_SITREP = 'set_product_sitrep'
export const SET_PRAC_DID_UPDATE = 'set_prac_did_update'
export const SET_WI_CHANGE_ARRAY = 'set_wi_change_array'
export const SET_WI_CHANGE_ORDER = 'set_wi_change_order'

// Reportal
export const SET_REPORT_FCST = 'set_report_fcst'
export const SET_REPORT_SELECTED = 'set_report_selected'
export const SET_REPORT_SECTION_CODE = 'set_report_section_code'
export const SET_REPORT_SECTION_CODE_Z = 'set_report_section_code_z'
export const SET_REPORT_RESOURCE = 'set_report_resource'
export const SET_REPORT_RESOURCE_Z = 'set_report_resource_z'
export const SET_REPORT_FISCAL_YEAR = 'set_report_fiscal_year'
export const SET_REPORT_FISCAL_YEAR_Z = 'set_report_fiscal_year_z'
export const SET_REPORT_METRIC = 'set_report_metric'
export const SET_REPORT_METRIC_Z = 'set_report_metric_z'
export const SET_REPORT_FILTER_ITEM = 'set_report_filter_item'
export const REPORT_FILTER_DISABLE = 'report_filter_disable'
export const SET_REPORT_FILTER_FCST = 'set_report_filter_fcst'
export const SET_REPORT_FILTER_SECTION_CODE = 'set_report_filter_section_code'
export const SET_REPORT_FILTER_RESOURCE = 'set_report_filter_resource'
export const SET_REPORT_FILTER_FY = 'set_report_filter_fy'
export const SET_REPORT_FILTER_METRIC = 'set_report_filter_metric'
export const SET_REPORT_PRODUCT_LIST = 'set_report_product_list'
export const SET_REPORT_SELECTED_PRODUCT = 'set_report_selected_product'
export const SET_REPORT_PRODUCT_UPDATE = 'set_report_product_update'
export const SET_REPORT_FILTER_PRODUCT = 'set_report_filter_product'
export const SET_REPORT_RESOURCE_OPTIONS = 'set_report_resource_options'
export const FLUSH_REPORT_INFO = 'flush_report_info'
export const SET_REPORTING_STATUS = 'set_reporting_status'
export const SET_REPORT_FILTER_REPORTING_STATUS = 'set_report_filter_reporting_status'
export const SET_REPORT_DATA_IS_LOADING = 'set_report_data_is_loading'

// Control
export const SET_SEARCH_TYPE = 'set_search_type'
export const SET_DOH_MODE = 'set_doh_mode'
export const SET_GRID_OR_GRAPH = 'set_grid_or_graph'
export const STORE_SECTIONS = 'store_sections'
export const STORE_SECTIONS_RAW = 'store_sections_raw'
export const STORE_ORG_SELECT = 'store_org_select'
export const SET_DAYS_TYPE = 'set_days_type'
export const SET_EXPAND_P2 = 'set_expand_p2'
export const SET_SCHEDULE_AUTO_REFRESH = 'set_schedule_auto_refresh'
export const STORE_INNER_WINDOW_SIZE = 'store_inner_window_size'
export const SET_SHOW_P2_MILESTONES = 'set_show_p2_milestones'
export const STORE_P2_MILESTONES = 'store_p2_milestones'

// Labor Code Tracking
export const STORE_SELECTED_TRACKING_P2 = 'store_selected_tracking_p2'
export const STORE_TRACKING_P2_Z = 'store_tracking_p2_z' // Product loaded and searched
export const STORE_SELECTED_TRACKING_FWI = 'store_selected_tracking_fwi'
export const STORE_TRACKING_FWI_Z = 'store_tracking_fwi_z'
export const STORE_SELECTED_TRACKING_LC = 'store_selected_tracking_lc'
export const STORE_TRACKING_LC_Z = 'store_tracking_lc_z'
export const STORE_TRACKING_SELECTION_GRID = 'store_tracking_selection_grid'
export const STORE_TRACKING_PRAC_RESULT_GRID = 'store_tracking_prac_result_grid'
export const SET_PRAC_SELECTION_UPDATE = 'set_prac_selection_update'
export const STORE_SELECTED_TRACKING_RC = 'store_selected_tracking_rc'
export const STORE_TRACKING_RC_Z = 'store_tracking_rc_z'
export const CLEAR_TRACKING_STATE = 'clear_tracking_state'
export const CLEAR_TRACKING_MOST = 'clear_tracking_most'
export const SET_TRACKING_FILTER_OPTIONS = 'set_tracking_filter_options'
export const SET_TRACKING_GRAPH_SELECTED = 'set_tracking_graph_selected'
export const STORE_TRACKING_GRAPH_DATA_DOLLARS = 'store_tracking_graph_data_dollars'
export const STORE_TRACKING_GRAPH_DATA_HOURS = 'store_tracking_graph_data_hours'
export const SET_TRACKING_GRAPH_LOADED = 'set_tracking_graph_loaded'
export const SET_PRAC_FLUSH_LOCAL = 'set_prac_flush_local'
export const SET_LC_TRACKING_SEARCH_ACTION = 'set_lc_tracking_search_action'
export const SET_LC_P2_SEARCH_ACTION = 'set_lc_p2_search_action'
export const SET_LC_WI_SEARCH_ACTION = 'set_lc_wi_search_action'
export const SET_LC_LC_SEARCH_ACTION = 'set_lc_lc_search_action'
export const RESET_LC_SEARCH_ACTIONS = 'reset_lc_search_actions'
export const SET_UPDATE_TRACKED = 'set_update_tracked'

// Resource report
export const SET_RSC_REPORT_FILTER = 'set_rsc_report_filter'
export const CLEAR_RSC_REPORT_FILTER = 'clear_rsc_report_filter'
export const PURGE_RSC_REPORT_DATA = 'purge_rsc_report_data'
export const SET_RSC_REPORT_GRID_PRODUCT_DOLLARS = 'set_rsc_report_grid_product_dollars'
export const SET_RSC_REPORT_GRID_RESOURCE_DOLLARS = 'set_rsc_report_grid_resource_dollars'
export const SET_RSC_REPORT_GRID_PRODUCT_HOURS = 'set_rsc_report_grid_product_hours'
export const SET_RSC_REPORT_GRID_RESOURCE_HOURS = 'set_rsc_report_grid_resource_hours'
export const SET_RSC_REPORT_GRAPH_RESOURCE_DOLLARS = 'set_rsc_report_graph_resource_dollars'
export const SET_RSC_REPORT_GRAPH_RESOURCE_HOURS = 'set_rsc_report_graph_resource_hours'
export const SET_GRAPH_SELECTED = 'set_graph_selected'
export const SET_GRAPH_UPDATE_KICKER = 'set_graph_update_kicker'
export const SET_RSC_REPORT_GRAPH_RAW_DATA_D = 'set_rsc_report_graph_raw_data_d'
export const SET_RSC_REPORT_GRAPH_RAW_DATA_H = 'set_rsc_report_graph_raw_data_h'
export const SET_RSC_GRAPH_DASH_DATA = 'set_rsc_graph_dash_data'

// MGMT Resource Tracking
export const SET_MGMT_RESOURCE_TRACKING_GRAPH_SELECTED = 'set_mgmt_resource_tracking_graph_selected'
export const SET_MGMT_RESOURCE_TRACKING_FILTER_SELECTED = 'set_mgmt_resource_tracking_filter_selected'
export const STORE_MGMT_RESOURCE_TRACKING_GRAPH_DATA_DOLLARS = 'store_mgmt_resource_tracking_graph_data_dollars'
export const STORE_MGMT_RESOURCE_TRACKING_GRAPH_DATA_HOURS = 'store_mgmt_resource_tracking_graph_data_hours'
export const SET_MGMT_RESOURCE_TRACKING_GRAPH_LOADED = 'set_mgmt_resource_tracking_graph_loaded'
export const FLUSH_MGMT_RESOURCE_TRACKING = 'flush_mgmt_resource_tracking'

// MGMT Approvals
export const SET_MGMT_APPROVALS_SELECTED = 'set_mgmt_approvals_selected'
export const SET_MGMT_APPROVALS_COMMENT_SELECTED = 'set_mgmt_approvals_comment_selected'
export const SET_MGMT_APPROVALS_RESPONSE_SELECTED = 'set_mgmt_approvals_response_selected'
export const SET_MGMT_APPROVALS_REPONSES_FOR_COMMENT = 'set_mgmt_approvals_responses_for_comment'
export const STORE_MGMT_APPROVALS_COMMENTS = 'store_mgmt_approvals_comments'
export const STORE_MGMT_APPROVALS_RESPONSES = 'store_mgmt_approvals_responses'
export const SET_MGMT_APPROVALS_WHOAMI = 'set_mgmt_approvals_whoami'
export const PURGE_MGMT_APPROVALS = 'purge_mgmt_approvals'

// Version Control
export const SET_VC_DOLLARS = 'set_version_control_dollars'
export const SET_VC_PRODUCTS_DOLLARS = 'set_version_control_products_dollars'
export const SET_VC_SECTIONS_DOLLARS = 'set_version_control_sections_dollars'
export const SET_VC_RESOURCES_DOLLARS = 'set_version_control_resource_dollars'
export const SET_VC_HOURS = 'set_version_control_hours'
export const SET_VC_PRODUCTS_HOURS = 'set_version_control_products_dollars'
export const SET_VC_SECTIONS_HOURS = 'set_version_control_sections_dollars'
export const SET_VC_RESOURCES_HOURS = 'set_version_control_resource_dollars'
//
export const SET_VC_SECTION_FILTER = 'set_version_controls_section_filter'
export const SET_VC_RESOURCE_FILTER = 'set_version_controls_resource_filter'
export const PURGE_VC = 'purge_version_control'
//
export const SET_VC_SELECTED_ROWS = 'set_version_control_selected_rows'
export const SET_VC_GRAPH_SELECTED = 'set_version_control_graph_selected' // Resources
export const SET_VC_FILTER_SELECTED = 'set_version_controls_filter_selected' // Sections
export const SET_VC_FILTER_ITEMS = 'set_version_controls_filter_items' 
//
export const SET_VC_VIEW_TYPE = 'set_version_control_view_type' // Cumulative or Monthly

// productLineItems={productLineItems}
// sectionLineItems={sectionLineItems}
// resourceLineItems={resourceLineItems}

// SSB Export
export const SET_EXPORT_OPTIONS = 'set_export_options'
export const SET_EXECUTE_EXPORT = 'set_execute_export'
export const SET_DELIVER_EXPORT = 'set_deliver_export'
export const SET_EXPORT_CONTENT = 'set_export_content'

// SSB Export Package
export const SET_EXPORT_PACKAGE = 'set_export_package'

// Email
export const SET_EMAIL_USE_CLIENT = 'set_email_use_client'
export const SET_EMAIL_OPTION = 'set_email_option'
export const SET_EMAIL_TO = 'set_email_to'
export const SET_EMAIL_ALT_TO = 'set_email_alt_to'
export const SET_EMAIL_CC = 'set_email_cc'
export const SET_EMAIL_ALT_CC = 'set_email_alt_cc'
export const SET_EMAIL_SUBJECT = 'set_email_subject'
export const SET_EMAIL_BODY = 'set_email_body'
export const SET_EMAIL_ALT_BODY = 'set_email_alt_body'
export const SET_EMAIL_ATTACHMENT = 'set_email_attachment'
export const SET_EMAIL_WL_MANAGERS = 'set_email_wl_managers'

// Cache Keys, for lazy cacheing need shallow uninvolved actions
export const SET_CACHE_USER_CONSENT = 'set_cache_user_consent'
export const SET_CACHE_USER_ENTITY = 'set_cache_user_entity'
export const SET_CACHE_SYS_VERSION = 'set_cache_sys_version'

// Idle
export const SET_CLIENT_IDLE_STATUS = 'set_client_idle_status' 
export const SET_CLIENT_IS_IDLE = 'set_client_is_idle'
export const SET_CLIENT_FORCED_LOGOUT = 'set_client_forced_logout'
export const SET_TICK_TOCK = 'set_tick_tock'
export const SET_LOGOUT_COUNTER = 'set_logout_counter'

// SysStat
export const SET_RDS_LAST_STAT = 'set_rds_last_stat'
export const SET_RDS_STATUS = 'set_rds_status'
export const SET_RDS_QUERY_PENDING = 'set_rds_query_pending'
export const SET_RDS_ERR_STAT = 'set_rds_error_stat'
export const SET_RDS_LAST_SUCCESS = 'set_rds_last_success'

// environment
export const SET_IS_DEV = 'set_is_dev'
export const SET_CURRENT_SYSTEM_STATUS = 'set_current_system_status'
export const SET_EMULATION_ACTIVE = 'set_emulation_active'
export const SET_EMULATION_PRODUCT = 'set_emulation_product'
export const SET_EMULATION_USER = 'set_emulation_user'
export const SET_EMULATION_ENTITY = 'set_emulation_entity'
export const SET_EMULATION_SOURCE = 'set_emulation_source'
export const SET_EMULATION = 'set_emulation'

// Access
export const SET_ACCESS_BULK = 'set_access_bulk'
export const SET_DENIED_ACCESS = 'set_denied_access'
export const SET_ROLES_ENFORCED = 'set_roles_enforced'
export const SET_MODULE_ACCESS = 'set_module_access'
export const SET_ROLES_GLOBAL_LOCK = 'set_roles_global_lock'
export const SET_ROLES_SOURCE = 'set_roles_source'

// Config
export const SET_EMS_CONFIG = 'set_ems_config'
export const SET_EMS_CONFIG_BY_KEY = 'set_ems_config_by_key'

// News
export const SET_NEWS_INDEX = 'set_news_index'

// Training Videos
export const SET_VIDEO_INDEX = 'set_video_index'

// Generic Validation Holder
export const SET_GENERIC_VALIDATION_ERROR = 'set_generic_validation_error'

// Configuration Module
export const SET_CONFIG_ENTITY = 'set_config_entity'
export const SET_CONFIG_LAST_PRODUCT_NAME = 'set_config_last_product_name'
export const SET_CONFIG_LAST_PRODUCT_ID = 'set_config_last_product_id'
export const SET_CONFIG_PRODUCT_TYPES = 'set_config_product_types'
export const SET_CONFIG_PROGRAM_TYPES = 'set_config_program_types'
export const SET_CONFIG_SELECTED_PROD_TYPE = 'set_config_selected_prod_type'
export const SET_CONFIG_SELECTED_PROG_TYPE = 'set_config_selected_prog_type'
export const SET_CONFIG_PERSIST_LOAD = 'set_config_persist_load'
export const SET_CONFIG_EFFECTIVE_DATE = 'set_config_effective_date'

// User Prefs
export const SET_USER_THEME = 'set_user_theme'
export const SET_USER_STYLE_PATHS = 'set_user_style_paths'
export const SET_USER_STYLE_LOADED = 'set_user_style_loaded'
export const SET_AG_THEME = 'set_ag_theme'

// Taxes
export const SET_MISC_TAXES = 'set_misc_taxes'
export const SET_MISC_TAXES_CURRENT_ROW = 'set_misc_taxes_current_row'
export const SET_MISC_TAXES_CURRENT_CELL = 'set_misc_taxes_current_cell'
export const SET_MISC_TAXES_OVER_RIDE_KEYS = 'set_misc_taxes_over_ride_keys'
export const SET_MISC_TAXES_CAN_REMOVE = 'setmisc_taxes_can_remove'
